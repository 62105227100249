/* eslint-disable indent */
export const TRANSLATION_IT = {
  i18n: {
    language: "Lingua",
    languageName: "Italiano",
  },
  faq: {
    instruction: "Istruzioni per l'uso",
  },
  visualizerComponent: {
    measurementArrowsLabelFormat: "SE {}", // Spessore elemento
  },
  highlightComponent: {
    next: "Avanti",
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "Email",
    userPasswordPlaceholder: "Password",
    repeatPasswordPlaceholder: "Ripeti la password",
    newPasswordPlaceholder: "Nuova password",
    repeatNewPasswordPlaceholder: "Ripeti la nuova password",
    userGenderPlaceholder: "Genere",
    userCompanyPlaceholder: "Azienda",
    userCompanyVATNumberPlaceholder: "Numero di partita IVA svizzera",
    userNamePlaceholder: "Nome",
    userFirstNamePlaceholder: "Nome",
    userLastNamePlaceholder: "Cognome",
    userFunctionPlaceholder: "Funzione",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "Via + nr",
    userZIPPlaceholder: "CAP",
    userCityPlaceholder: "Città",
    userPhonePlaceholder: "Telefono",
    searchField: "Ricerca",
    userGenderMale: "Maschio",
    userGenderFemale: "Femmina",
    userGenderDiv: "Diverso",
    userFunctionOwner: "Proprietario",
    userFunctionCoOwner: "Co-proprietario",
    userFunctionManagement: "Direzione",
    userFunctionExecutiveManagement: "Direzione Esecutiva",
    userFunctionPurchasingProcurement: "Acquisti / Approvvigionamento",
    userFunctionSales: "Vendite",
    userFunctionProjectManagement: "Gestione Progetti",
    userFunctionCalculationCosting: "Calcolo / Costi",
    userFunctionAdministrativeProcessing: "Elaborazione Amministrativa",
    userFunctionOther: "Altro",
  },
  commonAppValues: {
    types: "# tipi",
    created: "Creato",
    lastEdited: "Ultima modifica",
    configuration: "Configurazione",
    company: "Azienda",
    user: "Utente",
    orders: "Ordini",
    openOrders: "Ordini aperti",
    openRequests: "Richieste aperte",
    drafts: "Bozze",
    requests: "Richieste",
    addPosition: "Aggiungi posizione",
    companies: "aziende",
    configurationCodeLabel: "codice",
  },
  notifications: {
    loadConfiguration: "Caricamento della configurazione...",
    configurationUpdatedSuccessfully: "Configurazione aggiornata con successo",
    configurationIsSavedInTheCompany:
      "La configurazione è stata salvata nella tua azienda",
    configurationUpdatedError:
      "Si è verificato un errore, la configurazione non è stata aggiornata",
    configurationNotFound: "Configurazione non trovata",
    confirmationOfPositionDeletion:
      "Sei sicuro di voler eliminare questa posizione?",
    confirmationOfGlassDeletion:
      "Sei sicuro di voler eliminare questa configurazione del vetro?",
    confirmationOfGlassEdit:
      "Vuoi modificare la configurazione del vetro?",
    errorAddingFileWrongFormat: "Puoi aggiungere solo immagini o file PDF",
    confirmationOfDocumentDeletion:
      "Sei sicuro di voler eliminare questo documento?",
    confirmationOfConfigurationDeletion:
      "Sei sicuro di voler eliminare questa configurazione?",
    errorLargeFileSizeFmt: "Dimensione massima del file {}",
    accountCreationInProgress: "Creazione dell'account in corso",
    accountCreatedSuccessfully: "Ti sei registrato con successo",
    orderSuccessful: "Il tuo ordine è stato inviato. Grazie!",
    quoteSuccessful: "La tua richiesta è stata inviata. Grazie.",
    emailSendingInProgress: "Attendere, invio del messaggio in corso",
    configurationDeletionInProgress:
      "Eliminazione della configurazione in corso",
    configurationWasDeletedSuccessfully:
      "La configurazione è stata eliminata con successo",
    passwordResetInProgress: "Reimpostazione della password in corso",
    passwordHasBeenSuccessfullyChanged:
      "La password è stata cambiata con successo",
    pleaseWait: "Attendere prego...",
    companyDataWasUpdated: "I dati della tua azienda sono stati aggiornati",
    emailWithInstuctionsToResetPasswordHasBeenSent:
      "Ti è stata inviata un'email con le istruzioni per reimpostare la tua password",
    confirmationOfChangeTypeConfiguration:
      "Sei sicuro di voler cambiare il tipo di questa configurazione?",
    configurationTypeChangeInProgress:
      "Cambio del tipo di configurazione in corso",
    configTypeChangedSuccessfully:
      "Il tipo di configurazione è stato cambiato con successo",
    configurationStatusChangeInProgress:
      "Cambio dello stato della configurazione in corso",
    configurationStatusChangedSuccessfully:
      "Lo stato della configurazione è stato cambiato con successo",
    duplicatingConfigWithAllFilesInProgress:
      "Attendere. Duplicazione della configurazione con tutti i suoi file in corso",
    configurationDuplicationCompletedSuccessfully:
      "La duplicazione della configurazione è stata completata con successo",
    confirmationOfClientCompanyDeletion:
      "Sei sicuro di voler eliminare questo cliente con i suoi utenti e le sue configurazioni?",
    clientCompanyDeletionInProgress:
      "Eliminazione dell'azienda cliente in corso",
    clientCompanyWasDeletedSuccessfully:
      "L'azienda cliente è stata eliminata con successo",
    companyNotFound: "Azienda non trovata",
    loadingCompanyData: "Caricamento dei dati dell'azienda in corso...",
    dataLoadedSuccessfully: "Dati caricati con successo",
    dataHasBeenSuccessfullyUpdated:
      "I dati sono stati aggiornati con successo",
    userHasBeenSuccessfullyRemovedFromTheCompany:
      "L'utente è stato rimosso con successo dall'azienda",
    confirmationOfUserDeletion:
      "Sei sicuro di voler rimuovere questo utente dall'azienda?",
    invitationLinkHasBeenCopied: "Il link è stato copiato negli appunti",
    anErrorHasOccurred: "Si è verificato un errore",
    loadingDataOfAllCompaniesInProgress:
      "Caricamento dei dati di tutte le aziende in corso...",
    uploadDocAreaDragAndDrop: "Trascina qui i file",
    uploadDocAreaOr: "oppure",
    uploadDocAreaBrowseFiles: "Sfoglia i file sul tuo computer",
    uploadDocAreaMaxFileSizeFmt: "Dimensione massima del file {}",
    attachmentDeletionInProgress: "Eliminazione dell'allegato in corso...",
    attachmentWasDeletedSuccessfully: "L'allegato è stato eliminato",
  },
  formNotifications: {
    emailRequired: "L'email è obbligatoria",
    enterValidEmail: "Inserisci un'email valida",
    VATNumberRequired: "Nummero IVA è obbligatoria",
    enterValidVATNumber: "Inserisci un numero di partita IVA svizzero valido (CHE-000.000.000)",
    fieldIsRequired: "è necessario",
    nameFieldInvalidCharacters: "Caratteri non validi",
    nameFieldCharactersLength: "Deve contenere almeno tre caratteri",
    passwordFieldCharactersLong: "La password deve essere lunga almeno 8 caratteri",
    passwordFieldMustContainNumber:
      "La password deve contenere almeno un numero",
    passwordNotMatched: "Le password non corrispondono",
  },
  configurationCategory: {
    glassType: "Tipo di vetro",
    fireResistant: "Vetro resistente al fuoco",
    spacer1: "Distanziatore",
    gas1: "Gas",
    middleGlass: "Vetro medio (Rivestito)",
    spacer2: "Distanziatore",
    gas2: "Gas",
    outsideGlass: "Vetro esterno (Rivestito)",
    structure: "Struttura",
    structure_info: "",
    manufacturer: "Produttore",
    manufacturer_info: "",
    fireResistanceClass: "Resistenza al fuoco",
    fireResistanceClass_info: "Secondo la norma EN 13501-2",
    insulation: "Valore U",
    insulation_info: "In [W/m²K]\nSecondo la norma EN 673",
    application: "Applicazione",
    application_info: "",
    whiteGlass: "Vetro bianco",
    whiteGlass_info: "",
    resistanceClass: "Classe di resistenza",
    resistanceClass_info: "Secondo la norma EN 1627",
    buttJoints: "giunti di testa",
    buttJoints_info: "",
    sound: "Isolamento acustico Rw",
    sound_info: "Secondo la norma EN 140-3",
    thickness: "Spessore",
    sqmWeight: "Kg/m²",
    sqmPrice: "CHF/m²",
  },
  filterName: {
    StructureFilter: "Struttura",
    ManufacturerFilter: "Produttore",
    FireClassFilter: "Resistenza al fuoco",
    InsulationFilter: "Valore U",
    ApplicationFilter: "Applicazione",
    WhiteGlassFilter: "Vetro bianco",
    ResistanceClassFilter: "Classe di resistenza",
    ButtJointsFilter: "giunti di testa",
    SoundFilter: "Isolamento acustico",
  },
  commonConfigurationAction: {
    recordPositions: "Registrare posizioni",
    updateGlassType: "Aggiorna il tipo di vetro",
    resetGlass: "Reimposta il vetro",
    resetConfiguration: "Reimposta tutte le posizioni",
    saveAsPdf: "Salva come PDF",
    creatingPdf: "Creazione del PDF in corso...",
    glassDotPdfFmt: "CDR_Vetro_{date}.pdf",
    configurationDotPdfFmt: "CDR_Configurazione_{date}.pdf",
    backToPositionsEntry: "Torna alla registrazione delle posizioni",
    continue: "Continua",
    continueWithoutReference: "Continua senza Commissione",
    open: "Aprire",
    reopen: "Riaprire",
    delete: "Elimina",
    complete: "Completa",
    edit: "Modifica",
    doOrder: "Ordine",
    doRequest: "Richiedi un preventivo",
    duplicateAsDraft: "Duplicare come bozza",
    firstSelectProperties: "Selezione delle proprietà",
    secondCustomize: "Adattare",
    sizeValidationError:
      "Assicurati di compilare tutti i campi obbligatori: larghezza, altezza e quantità.",
    selectProductToContinue: "Seleziona un prodotto per continuare.",
    priceInfoBox:
      "Prezzo giornaliero incl. consegna magazzino & sovrapprezzo energetico\nescl. tassa veicoli pesanti (TTPCP) & IVA\nSuperficie minima di calcolo per pezzo: 0,5 m²\nPrezzo valido fino a 25 m², per quantità maggiori si prega di richiedere.\nBicchieri sagomati +50%.",
    priceNotAvailableInfoBox: "Il prezzo per questa posizione è attualmente disponibile solo su richiesta",
    loginForPriceInfoBox: "Accedi per vedere i prezzi",
    unknownPrice: "xxx.xx",
  },
  configurationTableHeading: {
    length: "Larghezza",
    width: "Altezza",
    linearMeterAbbrev: "ML",
    quantity: "Quantità",
    price: "Prezzo",
    form: "Forma",
    formNote:
      "Per i vetri sagomati, seleziona la casella. Per gli ordini, allega un disegno qui sotto. Per le forme circolari, basta una nota. Solo per le richieste di informazioni, la casella senza disegno è sufficiente.",
    notes: "Note",
    cantonalInsurance: "N. AEAI",
    cantonalInsuranceLink: "https://www.bsronline.ch/it/ricerca-dei-registri/",
    totalGlassType: "Totale Tipo di Vetro",
    totalCart: "Tutte le Posizioni",
    blocktotal: "Totale",
    lsva: "Tassa Veicoli Pesanti (TTPCP)",
    vat: "IVA",
    subtotal: "Totale parziale",
    totalPriceChf: "Importo totale [CHF]",
    pc: "pz",
    pos: "pos",
    kg: "kg",
    volumeDiscountFmt: "Per ordini a partire da {} m² è previsto uno sconto quantità. Ti preghiamo di continuare con la richiesta.",
    constructionSiteDeliveryFmt: "La consegna alla tua azienda è inclusa nel prezzo. Consegna in cantiere da {}",
  },
  registrationForm: {
    title: "Registrazione",
    registerForPrices: "Registrandoti, avrai accesso ai prezzi e al tuo dashboard personale.",
    registerButton: "Registrati",
    alreadyHaveAccount: "Hai già un account?",
    logIn: "Accedi",
  },
  loginForm: {
    title: "Accesso",
    dontHaveAccount: "Non hai un account?",
    loginButton: "Accedi",
    lostPassword: "Password persa",
    signUp: "Registrati",
    successfullyLoggedIn: "Accesso effettuato con successo!",
    loggingInProgress: "Accesso in corso",
    successfullyLoggedOut: "Disconnessione effettuata con successo",
  },
  lostPasswordForm: {
    dontHaveAccount: "Non hai un account?",
    title: "Password persa",
    submitButton: "Ripristina password",
    signUp: "Registrati",
    loggingInProgress: "Ripristino in corso",
    successfullyLoggedIn: "Ripristino riuscito",
  },
  resetConfigurationForm: {
    title: "Reimposta la configurazione",
    text: "Vuoi avviare una nuova configurazione e mantenere quella attuale? Il codice di configurazione è valido per 30 giorni",
    configurationCode: "Codice di configurazione",
    resetButton: "Reimposta",
    or: "o",
    textNew:
      "Vuoi avviare una nuova configurazione e cancellare tutti i contenuti e i file?",
    resetDeleteButton: "Reimposta e cancella",
    noConfigurationDefinedResetGlass: "Nessuna configurazione definita. Ripristina solo il vetro attuale.",
    confirmReset: "Vuoi avviare una nuova configurazione e cancellare tutti i contenuti e file?",
    backToConfigurator: "Per tornare al configuratore, clicca sulla matita nel titolo del tipo di vetro per modificarli.",
  },
  resetGlassForm: {
    title: "Reimposta il vetro",
    text: "Vuoi reimpostare il vetro e i filtri attuali?",
    resetButton: "Reimposta",
  },
  guestForm: {
    title: "Ospite",
    enterContactDetails: "Dacci i tuoi contatti così possiamo inviarti un'offerta via email.",
    additionalInfo: "Informazioni aggiuntive",
    continue: "Continua",
  },
  accountCompanyForm: {
    title: "Azienda",
  },
  accountPage: {
    companyFormTitle: "Azienda",
    userFormTitle: "Utente",
    usersInCompanyTitle: "utenti in azienda",
    registerNewUser: "Registra un nuovo utente per questa azienda",
    copyInviteLinkFmt: "Copia il link per l'autoregistrazione con {company} negli appunti",
    saveButton: "Salva",
  },
  configurationCodeForm: {
    title: "Codice di configurazione",
    enterYourCodePlaceholder: "Inserisci il tuo codice",
    overwriteConfigurationCheckbox:
      "questo sovrascriverà la tua configurazione attuale",
    submitButton: "carica",
  },
  resetPasswordForm: {
    title: "Password persa",
    submitButton: "Reimposta password",
  },
  homePage: {
    allStatementsWithoutGuarantee: "Tutte le informazioni senza garanzia",
    disclaimer: "Esclusione di responsabilità",
    disclaimerLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/Disclaimer-Configurator-IT.pdf",
    termsAndConditions: "Termini e condizioni",
    termsAndConditionsLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/CGC-IT.pdf",
    cookieSettings: "Impostazioni Cookie",
  },
  cartPage: {
    configurationCodeTitle: "Codice di configurazione",
    configurationCodeLabel: "codice",
    configurationCodeDescription:
      "Questo è il codice della tua configurazione, se vuoi recuperarlo più tardi senza fare il login. È valido per 30 giorni",
    yourReference: "Il Tuo Commissione / Riferimento",
    yourReferenceInputLabel: "Commissione 1",
    yourReferenceInputLabelPlaceholder: "",
    yourReferenceOptionalTitle: "",
    buildingReferenceInputLabel: "Commissione 2",
    buildingReferenceInputLabelPlaceholder: "",
    uploadedDocuments: "Documenti caricati",
    addNewTypeTitle: "Aggiungi un nuovo tipo di vetro",
    createFirstConfiguration: "Crea la tua prima configurazione",
    addNewTypeBtn: "Aggiungi",
    cartIsEmpty: "Nessuna posizione aggiunta",
    termsText:
      "Accetto le <a href=\"{termsURL}\" target=\"_blank\">condizioni generali</a> e <a href=\"{disclaimerURL}\" target=\"_blank\">l'esclusione di responsabilità</a>",
  },
  pageNavigation: {
    login: "Accedi",
    myCode: "Il mio codice",
    dashboard: "Cruscotto",
    account: "Account",
    logout: "Esci",
    adminDashboard: "Cruscotto amministratore",
    configurator: "Configuratore",
  },
  workflowNavigation: {
    configure: "Configura",
    cart: "Registrare Posizioni",
    requestOrOrder: "Richiesta / Ordine",
  },
  requestOrOrderPage: {
    doRequest: "Richiesta",
    doOrder: "Ordine",
    preferredQuoteDate: "Offerta a",
    preferredOrderDeliveryDate: "Consegna il",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Conferma dell'ordine",
    quoteConfirmationTitle: "Conferma della richiesta di preventivo",
    emailConfirmationSentToFmt: "Una conferma via email è stata inviata a {email}.",
    backToStart: "Torna all'inizio",
  },
  adminDashboardPage: {
    clientsTitle: "Clienti",
  },
  contactCompanyInformation: {
    showPrices: "Mostra prezzi",
    customerReference: "N. Cliente",
    company: "Azienda",
    address: "Indirizzo",
    zip: "CAP",
    city: "città",
    tel: "Tel",
    email: "Email",
  },
  genericWarningPopup: {
    warning: "Attenzione",
    confirm: "Conferma",
    abort: "Annulla",
  },
  deletePositionPopup: {
    title: "Elimina posizione",
    description: "Sei sicuro di voler eliminare questa posizione?",
    deleteButton: "Elimina",
  },
  deleteAttachmentPopup: {
    title: "Elimina allegato",
    description: "Sei sicuro di voler eliminare questo allegato?",
    deleteButton: "Elimina",
  },
  supportedDevicesPopup: {
    title: "Mobile",
    description:
      "Questo configuratore non è attualmente supportato dai dispositivi mobili. Utilizza un computer desktop o ridimensiona il browser",
  },
  glassCharacteristics: {
    glassStructure: "Struttura",
    fireResistance: "Resistenza al fuoco",
    uValue: "Valore U",
    thickness: "Spessore",
    clearGlass: "Vetro trasparente",
    resistanceClass: "Classe di resistenza",
    buttJoints: "Adatto per giunzioni a testa",
    application: "Applicazioni adatte",
    sound: "Smorzamento del suono",
    valid: "Configurazione valida",
    glassMaxSizeWarningFormat:
      "Dimensione massima {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Non disponibile a causa dei filtri",
  },
  filters: {
    changesUValue: "Cambia il valore U",
    gas_argon: "Argon",
    gas_krypton: "Kripton",
    gas_mixedGas: "Gas misto",
    gas_expensive:
      "Il prezzo di questo gas è attualmente molto volatile e costoso",
    spacer_format: "{} mm",
    structure_1Glazed: "Singolo (MONO)",
    structure_2Glazed: "Doppio (ISO)",
    structure_3Glazed: "Triplo (ISO)",
    manufacturer_indep: "Indipendente",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "{}",
    application_in: "Interno",
    application_inOut: "Interno/Esterno",
    application_outOut: "Esterno/Esterno",
    whiteGlass_yes: "Sì",
    whiteGlass_no: "No",
    resistanceClass_none: "Nessuna",
    resistanceClass_rc2: "RC2 (P4A)",
    resistanceClass_rc3: "RC3 (P5A)",
    resistanceClass_rc4: "RC4 (P6B)",
    buttJoints_yes: "Sì",
    buttJoints_no: "No",
    sound_format: "{} dB",
    sound_format_better_than: "min {} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Pagina {pageNum} di {numPages}",
    drafts: "Bozza",
    order: "Ordine",
    orderDeliveryDateFmt: "Consegna il {}",
    request: "Richiesta",
    requestDueDateFmt: "Offerta a {}",
  },
  firebaseErrorMessages: {
    // errors for Cloud Storage
    "storage/unknown": "Si è verificato un errore sconosciuto.",
    "storage/object-not-found": "Nessun oggetto esiste al riferimento desiderato.",
    "storage/bucket-not-found": "Nessun bucket è configurato per lo Storage Cloud.",
    "storage/project-not-found": "Nessun progetto è configurato per lo Storage Cloud.",
    "storage/quota-exceeded": "La quota sul tuo bucket di Storage Cloud è stata superata.",
    "storage/unauthenticated": "L'utente non è autenticato, si prega di autenticarsi e riprovare.",
    "storage/unauthorized": "L'utente non è autorizzato a eseguire l'azione desiderata, controlla le tue regole di sicurezza per assicurarti che siano corrette.",
    "storage/retry-limit-exceeded": "Il limite di tempo massimo su un'operazione (caricamento, download, cancellazione, ecc.) è stato superato. Prova a caricare di nuovo.",
    "storage/invalid-checksum": "Il file sul client non corrisponde al checksum del file ricevuto dal server. Prova a caricare di nuovo.",
    "storage/canceled": "L'utente ha annullato l'operazione.",
    "storage/invalid-event-name": "Nome evento non valido fornito. Deve essere uno dei seguenti [`running`, `progress`, `pause`]",
    "storage/invalid-url": "URL non valido fornito a refFromURL(). Deve essere nella forma: gs://bucket/oggetto o https://firebasestorage.googleapis.com/v0/b/bucket/o/oggetto?token=<TOKEN>",
    "storage/invalid-argument": "L'argomento passato a put() deve essere un `File`, `Blob` o un array `UInt8`. L'argomento passato a putString() deve essere una stringa grezza, `Base64` o `Base64URL`.",
    "storage/no-default-bucket": "Nessun bucket è stato impostato nella proprietà storageBucket della tua configurazione.",
    "storage/cannot-slice-blob": "Si verifica spesso quando il file locale è cambiato (cancellato, salvato di nuovo, ecc.). Prova a caricare di nuovo dopo aver verificato che il file non sia cambiato.",
    "storage/server-file-wrong-size": "Il file sul client non corrisponde alla dimensione del file ricevuto dal server. Prova a caricare di nuovo.",

    // authentication API Errors
    "auth/claims-too-large": "Il payload delle claims fornito a setCustomUserClaims() supera la dimensione massima consentita di 1000 byte.",
    "auth/expired-action-code": "Il link è già scaduto. Reimposta nuovamente la password.",
    "auth/invalid-action-code": "Il link non è valido. Prova di nuovo o reimposta la password ancora una volta.",
    "auth/email-already-exists": "L'email fornita è già in uso da un utente esistente. Ogni utente deve avere un indirizzo email unico.",
    "auth/id-token-expired": "Il token ID Firebase fornito è scaduto.",
    "auth/id-token-revoked": "Il token ID Firebase è stato revocato.",
    "auth/insufficient-permission": "Il certificato utilizzato per inizializzare l'SDK Admin non ha i permessi necessari per accedere alla risorsa di autenticazione richiesta. Consulta la documentazione su Configurare un Progetto Firebase per informazioni su come generare un certificato con i permessi appropriati e usarlo per autenticare gli SDK Admin.",
    "auth/internal-error": "Il server di autenticazione ha incontrato un errore imprevisto durante il tentativo di elaborare la richiesta. Il messaggio di errore dovrebbe contenere la risposta del server di autenticazione con informazioni aggiuntive. Se l'errore persiste, si prega di segnalare il problema al nostro canale di supporto Bug Report.",
    "auth/invalid-argument": "È stato fornito un argomento non valido a un metodo di autenticazione. Il messaggio di errore dovrebbe contenere ulteriori informazioni.",
    "auth/invalid-claims": "Gli attributi delle claims personalizzate forniti a setCustomUserClaims() sono invalidi.",
    "auth/invalid-continue-uri": "L'URL di continuazione deve essere una stringa URL valida.",
    "auth/invalid-creation-time": "Il tempo di creazione deve essere una stringa di data UTC valida.",
    "auth/invalid-credential": "Il certificato utilizzato per autenticare gli SDK Admin non può essere utilizzato per eseguire l'azione desiderata. Alcuni metodi di autenticazione come createCustomToken() e verifyIdToken() richiedono che l'SDK sia inizializzato con un certificato come token di aggiornamento o un token di applicazione predefinito. Consulta la documentazione sull'inizializzazione dell'SDK per sapere come autenticare gli SDK Admin con un certificato.",
    "auth/invalid-disabled-field": "Il valore fornito per la proprietà disabilitato dell'utente è invalido. Deve essere un booleano.",
    "auth/invalid-display-name": "Il valore fornito per la proprietà displayName dell'utente è invalido. Deve essere una stringa non vuota.",
    "auth/invalid-dynamic-link-domain": "Il dominio del link dinamico fornito non è configurato o autorizzato per il progetto corrente.",
    "auth/invalid-email": "Il valore fornito per la proprietà email dell'utente è invalido. Deve essere un indirizzo email.",
    "auth/invalid-email-verified": "Il valore fornito per la proprietà emailVerified dell'utente è invalido. Deve essere un booleano.",
    "auth/invalid-hash-algorithm": "L'algoritmo di hashing deve corrispondere a una delle stringhe dell'elenco degli algoritmi supportati.",
    "auth/invalid-hash-block-size": "La dimensione del blocco di hash deve essere un numero valido.",
    "auth/invalid-hash-derived-key-length": "La lunghezza della chiave derivata dall'hash deve essere un numero valido.",
    "auth/invalid-hash-key": "La chiave di hash deve essere un buffer di byte valido.",
    "auth/invalid-hash-memory-cost": "Il costo della memoria dell'hash deve essere un numero valido.",
    "auth/invalid-hash-parallelization": "La parallelizzazione dell'hash deve essere un numero valido.",
    "auth/invalid-hash-rounds": "Il numero di round dell'hash deve essere un numero valido.",
    "auth/invalid-hash-salt-separator": "Il campo separatore di sale dell'algoritmo di hash deve essere un buffer di byte valido.",
    "auth/invalid-id-token": "Il token ID fornito non è un token ID Firebase valido.",
    "auth/invalid-last-sign-in-time": "L'ora dell'ultimo accesso deve essere una stringa di data UTC valida.",
    "auth/invalid-page-token": "Il token della pagina successiva fornito in listUsers() è invalido. Deve essere una stringa valida non vuota.",
    "auth/invalid-password": "Il valore fornito per la proprietà password dell'utente è invalido. Deve essere una stringa di almeno sei caratteri.",
    "auth/invalid-password-hash": "L'hash della password deve essere un buffer di byte valido.",
    "auth/invalid-password-salt": "Il sale della password deve essere un buffer di byte valido.",
    "auth/invalid-phone-number": "Il valore fornito per il numero di telefono è invalido. Deve essere una stringa conforme allo standard E.164.",
    "auth/invalid-photo-url": "Il valore fornito per la proprietà photoURL dell'utente è invalido. Deve essere una stringa URL.",
    "auth/invalid-provider-data": "providerData deve essere un array valido di oggetti UserInfo.",
    "auth/invalid-provider-id": "providerId deve essere una stringa valida di un identificatore di fornitore supportato.",
    "auth/invalid-oauth-responsetype": "Solo un tipo di risposta OAuth deve essere impostato su true.",
    "auth/invalid-session-cookie-duration": "La durata del cookie di sessione deve essere un numero valido in millisecondi tra 5 minuti e 2 settimane.",
    "auth/invalid-uid": "L'UID fornito deve essere una stringa non vuota di al massimo 128 caratteri.",
    "auth/invalid-user-import": "Il record dell'utente da importare è invalido.",
    "auth/maximum-user-count-exceeded": "È stato superato il numero massimo consentito di utenti da importare.",
    "auth/missing-android-pkg-name": "Deve essere fornito un nome del pacchetto Android se l'app Android deve essere installata.",
    "auth/missing-continue-uri": "Deve essere fornita una URL di continuazione valida nella richiesta.",
    "auth/missing-hash-algorithm": "L'importazione degli utenti con hash delle password richiede di fornire l'algoritmo di hash e i suoi parametri.",
    "auth/missing-ios-bundle-id": "Nella richiesta manca un ID bundle.",
    "auth/missing-uid": "È richiesto un UID per l'operazione corrente.",
    "auth/missing-oauth-client-secret": "È richiesto il segreto del client di configurazione OAuth per abilitare il flusso di codice OIDC.",
    "auth/network-request-failed": "Nessuna connessione",
    "auth/operation-not-allowed": "Il fornitore di login fornito è disabilitato per il tuo progetto Firebase. Attivalo dalla sezione Metodo di Login nella console Firebase.",
    "auth/phone-number-already-exists": "Il numero di telefono fornito è già in uso da un utente esistente. Ogni utente deve avere un numero di telefono unico.",
    "auth/project-not-found": "Nessun progetto Firebase è stato trovato per il certificato utilizzato per inizializzare gli SDK Admin. Consulta la documentazione su Configurare un Progetto Firebase per informazioni su come generare un certificato per il tuo progetto e usarlo per autenticare gli SDK Admin.",
    "auth/reserved-claims": "Una o più claims personalizzate dell'utente fornite a setCustomUserClaims() sono riservate. Ad esempio, claims specifiche per OIDC come (sub, iat, iss, exp, aud, auth_time, ecc.) non dovrebbero essere utilizzate come chiavi per claims personalizzate.",
    "auth/session-cookie-expired": "Il cookie di sessione Firebase fornito è scaduto.",
    "auth/session-cookie-revoked": "Il cookie di sessione Firebase è stato revocato.",
    "auth/too-many-requests": "Il numero di richieste supera il massimo consentito.",
    "auth/uid-already-exists": "L'UID fornito è già in uso da un utente esistente. Ogni utente deve avere un UID unico.",
    "auth/unauthorized-continue-uri": "Il dominio dell'URL di continuazione non è nella whitelist. Aggiungi il dominio alla console Firebase.",
    "auth/user-not-found": "Non esiste alcun record utente corrispondente all'identificativo fornito.",
    "auth/wrong-password": "Password errata",

    // other errors
    ok: "Operazione riuscita",
    cancelled: "Operazione annullata",
    unknown: "Errore sconosciuto",
    "invalid-argument": "Argomento non valido",
    "deadline-exceeded": "Scadenza superata",
    "not-found": "Non trovato",
    "already-exists": "Già esistente",
    "permission-denied": "Permesso negato",
    unauthenticated: "Utente non autenticato",
    "resource-exhausted": "Risorsa esaurita",
    "failed-precondition": "Precondizione fallita",
    aborted: "Operazione annullata",
    "out-of-range": "Fuori portata",
    unimplemented: "Non implementato",
    internal: "Errore interno",
    unavailable: "Non disponibile",
    "data-loss": "Perdita di dati",
  },
  email: {
    draft: "Bozza",
    request: "Richiesta",
    order: "Ordine",
    salutationFmt_male: "Caro {firstName}",
    salutationFmt_female: "Cara {firstName}",
    salutationFmt_div: "Caro/Cara {firstName}",
    bestRegards: "Cordiali saluti,",
    yourCDRTeam: "Il tuo team CDR",
    confirmationSubject_request: "La tua richiesta di preventivo CDR",
    confirmationSubject_order: "Il tuo ordine CDR",
    confirmationBodyFmt_request: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Grazie per la tua richiesta. Ci occuperemo immediatamente.</p><p>Troverai in allegato una copia della richiesta e dei documenti inviati.</p></div>{signature}",
    confirmationBodyFmt_order: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Grazie per il tuo ordine. Ci occuperemo immediatamente.</p><p>Troverai in allegato una copia dell'ordine e dei documenti inviati.</p></div>{signature}",
    showPricesSubject: "Visualizzazione dei prezzi del CDR Configurator",
    showPricesFmt: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Tutti i conti di {company} sono ora abilitati per la visualizzazione dei prezzi sul <a href=\"{configuratorURL}\">CDR Configurator</a></p></div>{signature}",
  },
  faqPage: {
    typeYourQuestion: "Scrivi la tua domanda",
    back: "Indietro",
    foundSingleAnswer: "Trovata una risposta",
    foundMultipleAnswersFmt: "Trovate {numAnswers} risposte",
  },
  datasheet: {
    datasheetForGlassFmt: "Scheda tecnica per {}",
    datasheetFmt: "Scheda tecnica {}",
  },
};
