import React from 'react';

import { BlockHeading } from 'components/BlockHeading';
import GlassConfigTable from './partials/GlassConfigTable';
import GlassConfigDetailFirst from './partials/GlassConfigDetailFirst';
import GlassConfigDetailSecond from './partials/GlassConfigDetailSecond';

const GlassConfig = ({
	parent,
	totalValues,
	glassData,
	glassIndex,
	attrKey,
	showPrice,
}) => {
	return (
		<div className="glass_config">
			<BlockHeading
				parent={parent}
				attrKey={attrKey}
				title={glassData.fireResistant.concat(glassData.flatGlassStructure.structure === '1-glazed' ? '' : ' ISO')}
				blockIndex={glassIndex}
				glassData={glassData}
				showGlassConfigButtons
				showPrice={showPrice}
			/>
			<GlassConfigDetailFirst
				configDetails={glassData}
			/>
			<GlassConfigDetailSecond
				configDetails={glassData}
				showPrice={showPrice}
			/>
			<GlassConfigTable
				parent={glassData}
				totalValues={totalValues}
				glassIndex={glassIndex}
				showPrice={showPrice}
			/>
		</div>
	);
};

export default GlassConfig;
