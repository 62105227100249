export const DEFAULT_GLASS_SIZES = {
	quantity: '-',
	m2: '-',
	linearMeter: '-',
	kg: '-',
	price: '-',
};

export const replaceZerosWithDash = (values) => {
	return Object.fromEntries(
		Object.entries(values).map(([key, value]) => [key, value === 0 ? '-' : value]),
	);
};

export const roundPriceToFiveCents = (price, roundUp = true) => (roundUp ? Math.ceil(price * 20) : Math.round(price * 20)) / 20;

const calculateRoundedPcSurface = (width, height) => {
	const surfaceInSquareMeters = (width * height) / 1000000;
	// CDR: Round to two decimals prior to multiplication with
	// quantity to match their calculation software: Round(x * 100) / 100
	return Math.round(surfaceInSquareMeters * 100) / 100;
};

const findItemByName = (row, name) => row.find(item => item.name === name) || { value: null };

export const checkIsFieldError = (fields, id, name) => {
	return fields.some(item => item.id === id && item.fields.some(field => field.name === name));
};

export const calculateSubTotalSizes = (positions, flatGlassStructure, showPrice, minimalGlassSurface, modelGlassFactor) => {
	const validRows = positions.filter(({ row }) => {
		return Array.isArray(row) && row.length > 0;
	});

	const emptyOrZeroFields = validRows.map(({ row, inactive, id }) => {
		const fields = row.filter(({ name, value }) => {
			const intValue = parseInt(value, 10);
			return !inactive && (name === "length" || name === "width" || name === "quantity") && (intValue === 0 || isNaN(intValue));
		});
		if (fields.length === 0) return null;
		return {
			id,
			fields,
		};
	}).filter(o => o !== null);

	const values = validRows
		.map(({ row, inactive }, index) => {
			if (inactive) return null;

			const { value: width } = findItemByName(row, "width");
			const { value: height } = findItemByName(row, "length");
			const { value: quantity } = findItemByName(row, "quantity");
			const { value: modelGlassChecked } = findItemByName(row, "form");
			const modelGlassPriceFactor = modelGlassChecked === 'checked' ? modelGlassFactor : 1.0;
			const numericQuantity = parseFloat(quantity);

			if (width && height && quantity && numericQuantity >= 1) {
				const parsedWidth = parseFloat(width);
				const parsedHeight = parseFloat(height);
				// CDR: Use the minimal glass surface for all calculations
				const m2PcReal = calculateRoundedPcSurface(parsedWidth, parsedHeight);
				const m2PcCalc = Math.max(minimalGlassSurface, m2PcReal);
				const kgPc = m2PcReal * flatGlassStructure.sqmWeight;
				const m2Pos = m2PcCalc * numericQuantity;
				const roundedPcPrice = roundPriceToFiveCents(m2PcCalc * flatGlassStructure.sqmPrice * modelGlassPriceFactor);
				const roundedPosPrice = roundedPcPrice * numericQuantity;
				// eslint-disable-next-line camelcase
				const linearMeter_mm = 2 * (parsedWidth + parsedHeight);
				// eslint-disable-next-line camelcase
				const linearMeter = (Math.ceil(linearMeter_mm / 10.0) / 100.0); // meters rounded up to 2 digits

				return {
					index,
					quantity: numericQuantity,
					m2Pc: m2PcCalc,
					m2: m2Pos,
					linearMeter,
					linearMeterPos: linearMeter * numericQuantity,
					kg: kgPc,
					kgPos: kgPc * numericQuantity,
					price: showPrice && flatGlassStructure.sqmPrice !== null ? roundedPosPrice : null,
				};
			}

			return null;
		});

	const validActiveRowLength = validRows.filter(({ inactive }) => {
		return !inactive;
	}).length;

	const sizes = values.filter(item => item !== null);
	const isFilled = validActiveRowLength === sizes.length;

	return { sizes, isFilled, emptyOrZeroFields };
};

export const calculateTotalSizes = (values, showPrice) => {
	return values.reduce((acc, innerValues) => {
		innerValues.forEach(({
			quantity, m2, linearMeterPos, kgPos, price,
		}) => {
			acc.quantity += quantity;
			acc.m2 += m2;
			acc.linearMeter += linearMeterPos;
			acc.kg += kgPos;
			acc.price = showPrice && acc.price !== null && price !== null ? acc.price + price : null;
		});
		return acc;
	}, {
		quantity: 0, m2: 0, linearMeter: 0, kg: 0, price: 0,
	});
};

export const getGlassSizes = (glassTypes, showPrice, configuratorData) => {
	const values = [];
	let oversizedPositions = [];
	let emptyOrZeroFields = [];
	let isSizesValid = true;

	const {
		min_pos_sqm: minimalGlassSurface,
		model_glass_factor: modelGlassFactor,
		// eslint-disable-next-line camelcase
		vat_percent, lsva_min, lsva_per_m2,
	} = configuratorData.pricing;
	const { max_glass_width: globalMaxGlassWidth, max_glass_height: globalMaxGlassHeight } = configuratorData.glassdb;

	glassTypes.map(({ positions, flatGlassStructure }) => {
		const { sizes, isFilled, emptyOrZeroFields: fields } = calculateSubTotalSizes(positions, flatGlassStructure, showPrice, minimalGlassSurface, modelGlassFactor);

		const os = positions.filter(({ row }) => {
			const noMaxH = flatGlassStructure.maxHeight === null;
			const noMaxW = flatGlassStructure.maxWidth === null;
			const l = parseInt(row.find(o => o.name === 'length')?.value, 10) || 0;
			const w = parseInt(row.find(o => o.name === 'width')?.value, 10) || 0;
			const width = Math.max(l, w); // on flatGlassStructure, width is guaranteed to be the wider measurement
			const length = Math.min(l, w);
			return (
				((!noMaxW && width > flatGlassStructure.maxWidth) || width > globalMaxGlassWidth)
				|| ((!noMaxH && length > flatGlassStructure.maxHeight) || length > globalMaxGlassHeight)
			);
		});

		emptyOrZeroFields = emptyOrZeroFields.concat(fields);
		oversizedPositions = oversizedPositions.concat(os);
		values.push(sizes);

		isSizesValid = isSizesValid && isFilled;
		return null;
	});

	const valuesSum = calculateTotalSizes(values, showPrice);
	// eslint-disable-next-line camelcase
	const vatFactor = vat_percent / 100.0;
	const hasPrice = !!valuesSum.price;
	// eslint-disable-next-line camelcase
	valuesSum.shippingCosts = hasPrice ? roundPriceToFiveCents(Math.max(lsva_min, valuesSum.m2 * lsva_per_m2)) : null;
	valuesSum.materialCosts = hasPrice ? roundPriceToFiveCents(valuesSum.price) : null;
	// shippingCosts and materialCosts are already rounded to 5cents, so we don't need to round again. If we do we get float issues:
	// 9.3 + 4135.05 = 4144.3500000001 which is rounded up again unless we tell it to just round that one. So we round instead of rounding up.
	valuesSum.subtotal = hasPrice ? roundPriceToFiveCents(valuesSum.shippingCosts + valuesSum.materialCosts, false) : null;
	valuesSum.vatCosts = hasPrice ? roundPriceToFiveCents(valuesSum.subtotal * vatFactor) : null;
	valuesSum.total = hasPrice ? valuesSum.subtotal + valuesSum.vatCosts : null;
	replaceZerosWithDash(valuesSum);

	return {
		values, valuesSum, isSizesValid, emptyOrZeroFields, oversizedPositions,
	};
};
