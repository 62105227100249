import React from 'react';

import Logo from 'i/logo.png';
import {
	View, Image, Text, Link,
} from '@react-pdf/renderer';
import { telLink } from 'utils';
import i18n from 'utils/i18n';
import styles from './styles';

const PdfHeader = ({
	documentType, contactInfo, customerInfo, dueDateText,
}) => {
	const lang = i18n.language;

	return (
		<View style={styles.header}>
			<View style={styles.headerLeftGroup}>
				<View style={styles.headerLogo}>
					<Image src={Logo} style={styles.headerLogoImg} />
				</View>

				{contactInfo ? (
					<View style={styles.headerContactInfo}>
						<Text style={styles.headerContactInfoItem}>{contactInfo.address_1[lang]}</Text>
						<Link style={styles.headerContactInfoItem} src={telLink(contactInfo.contact_1_phone)}>
							{contactInfo.contact_1_phone}
						</Link>
						<Text style={styles.headerContactInfoItem}>{contactInfo.address_2[lang]}</Text>
						<Link style={styles.headerContactInfoItem} src={`mailto:${contactInfo.contact_2_email}`}>
							{contactInfo.contact_2_email}
						</Link>
					</View>
				) : null}
			</View>

			<View style={styles.headerCenterGroup}>
				{documentType ? (
					<Text style={styles.documentType}>{documentType}</Text>
				) : null}
				{dueDateText ? (
					<Text style={styles.dueDate}>{dueDateText}</Text>
				) : null}
			</View>

			<View style={styles.headerRightGroup}>
				{customerInfo ? (
					<View style={styles.headerContactInfo}>
						{customerInfo.companyName ? <Text style={styles.headerCustomerInfoItem} key="companyName">{customerInfo.companyName}</Text> : null}
						{customerInfo.companyStreet ? <Text style={styles.headerCustomerInfoItem} key="companyStreet">{customerInfo.companyStreet}</Text> : null}
						{customerInfo.userDisplayName ? <Text style={styles.headerCustomerInfoItem} key="userDisplayName">{customerInfo.userDisplayName}</Text> : null}
						{customerInfo.companyZip ? <Text style={styles.headerCustomerInfoItem} key="companyZipCity">{`${customerInfo.companyZip} ${customerInfo.companyCity}`}</Text> : null}
						{customerInfo.companyPhone ? <Text style={styles.headerCustomerInfoItem} key="companyPhone">{customerInfo.companyPhone}</Text> : null}
						{customerInfo.companyVATNumber ? <Text style={styles.headerCustomerInfoItemLC} key="companyVATNumber">{customerInfo.companyVATNumber}</Text> : null}
						{customerInfo.userEmail ? <Text style={styles.headerCustomerInfoItemLC} key="userEmail">{customerInfo.userEmail}</Text> : null}
					</View>
				) : (
					<Text style={styles.headerCustomerInfoItem}>&nbsp;</Text>
				)}
			</View>
		</View>
	);
};

export default PdfHeader;
