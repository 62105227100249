import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixed } from 'utils';
import GlassConfigDetailItem from '../GlassConfigDetailItem';

const GlassConfigDetailSecond = ({
	configDetails,
	showPrice,
}) => {
	const { t } = useTranslation('commonConfigurationAction');
	return (
		<ul className="glass_config__details glass_config__details--indent_state">
			<GlassConfigDetailItem
				categoryLabel="fireResistanceClass"
				selected={configDetails.fireResistanceClass}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="manufacturer"
				selected={configDetails.manufacturer}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="whiteGlass"
				selected={configDetails.whiteGlass}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="application"
				selected={configDetails.application}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="structure"
				selected={configDetails.structure}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="insulation"
				selected={configDetails.insulation}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="sound"
				selected={configDetails.sound}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="resistanceClass"
				selected={configDetails.resistanceClass}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="buttJoints"
				selected={configDetails.buttJoints}
				secondLayoutOption
			/>
			<GlassConfigDetailItem
				categoryLabel="sqmWeight"
				selected={formatNumberToFixed(configDetails.flatGlassStructure.sqmWeight, 0)}
				secondLayoutOption
			/>
			{showPrice && (
				<GlassConfigDetailItem
					categoryLabel="sqmPrice"
					selected={formatNumberToFixed(configDetails.flatGlassStructure.sqmPrice || t('unknownPrice'))}
					secondLayoutOption
				/>
			)}
		</ul>
	);
};

export default GlassConfigDetailSecond;
