export default class Insulation {
    static uValueTable = {
        '2-glazed': [],
        '3-glazed': []
    }

    static getUValueRangeForSpacer(glass_structure, spacer_mm) {
        if (glass_structure === '1-glazed') {
            glass_structure = '2-glazed';
        }
        let uVals = Insulation.uValueTable[glass_structure].filter(val => {
            if (glass_structure === '2-glazed') {
                return val.spacer1 == spacer_mm;
            } else if (glass_structure === '3-glazed') {
                return val.spacer1 == spacer_mm && val.spacer2 == spacer_mm;
            }
        });
        return {
            min: Math.min(...uVals.map(o => o.uValue)),
            max: Math.max(...uVals.map(o => o.uValue)),
        };
    }

    static getUValueRangeForGas(glass_structure, gas) {
        if (glass_structure === '1-glazed') {
            glass_structure = '2-glazed';
        }
        let uVals = Insulation.uValueTable[glass_structure].filter(val => {
            return val.gas == gas;
        });
        return {
            min: Math.min(...uVals.map(o => o.uValue)),
            max: Math.max(...uVals.map(o => o.uValue)),
        };
    }

    static getUValueRangeForStructure(glass_structure) {
        if (glass_structure === '1-glazed') {
            return { min: 5.4, max: 5.4 };
        }
        return {
            min: Math.min(...Insulation.uValueTable[glass_structure].map(o => o.uValue)),
            max: Math.max(...Insulation.uValueTable[glass_structure].map(o => o.uValue)),
        };
    }

    static calculateUValueForGlass(spacer1, spacer2, gas) {
        try {
            if (!spacer1) {
                return 0;
            } else if (!spacer2) {
                return Insulation.uValueTable['2-glazed'].filter(val => val.spacer1 === spacer1 && val.gas === gas)[0].uValue;
            } else {
                return Insulation.uValueTable['3-glazed'].filter(val => val.spacer1 === spacer1 && val.spacer2 === spacer2 && val.gas === gas)[0].uValue;
            }
        } catch (e) {
            return -1;
        }
    }
}
