/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { CONFIGURATIONS_API } from "api";
import { BlockHeading } from "components/BlockHeading";
import { ConfigurationDataContext } from "context/configuration";
import { UiContext } from "context/ui";
import { httpsCallable } from "firebase/functions";
import { fieldValidationEmptyValue } from "utils";
import { PROMISE_STATES } from "utils/constant";
import { firebaseFunctions } from "utils/firebase";
import ToastifyHandler from "utils/ToastifyHandler";
import FormField from "./FormField";
import FormCheckbox from "./FormCheckbox";

const ConfigurationCodeForm = () => {
  const { t } = useTranslation(["configurationCodeForm", 'firebaseErrorMessages']);
  const { setConfiguration, setUserCodeConfiguration } = useContext(
    ConfigurationDataContext
  );
  const { showPopupByKey } = useContext(UiContext);
  const [checkboxState, setCheckboxState] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
  const notificationsHandler = useRef(new ToastifyHandler());
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const { configurationCodeField } = e.target.elements;

    if (!configurationCodeField) return;

    const fieldValue = configurationCodeField.value.trim();

    if (!fieldValidationEmptyValue(fieldValue)) return;

    notificationsHandler.current.pending(t("notifications:loadConfiguration"));
    setLoadingStatus(PROMISE_STATES.pending);

    try {
      const configurationRes = await CONFIGURATIONS_API.getGuestConfiguration(
        fieldValue
      );

      if (configurationRes) {
        notificationsHandler.current.success(
          t("notifications:configurationUpdatedSuccessfully")
        );

        setUserCodeConfiguration(configurationRes.code);
        setLoadingStatus(PROMISE_STATES.fulfilled);
        setConfiguration(configurationRes);
        showPopupByKey();
        navigate("/cart");

        const updateConfigurationTimestamp = httpsCallable(
          firebaseFunctions,
          "updateConfigurationTimestamp"
        );
        updateConfigurationTimestamp({ id: fieldValue });
      } else {
        notificationsHandler.current.rejected(
          t("notifications:configurationNotFound")
        );
        setLoadingStatus(PROMISE_STATES.rejected);
      }
    } catch (error) {
      const { code } = error;
      notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
      setLoadingStatus(PROMISE_STATES.rejected);
    }
  };

  const onCheckboxHandler = (e) => {
    const { checked } = e.target;

    setCheckboxState(checked);
  };

  return (
    <div className="configuration_code_form">
      <form className="configuration_code_form__in" onSubmit={onSubmitHandler}>
        <BlockHeading title={t("title")} iconName="config_code" />
        <div className="form_fields offset_3_mod">
          <div className="configuration_code_form__input">
            <FormField
              id="configurationCodeField"
              placeholder={t("enterYourCodePlaceholder")}
              name="configurationCodeField"
              type="text"
              required
            />
          </div>
          <FormCheckbox
            id="configurationCodeOverwriteCheckbox"
            name="configurationCodeOverwriteCheckbox"
            title={t("overwriteConfigurationCheckbox")}
            onChange={onCheckboxHandler}
            isUppercase
          />
        </div>
        <button
          className="btn_v2"
          type="submit"
          disabled={
            loadingStatus === PROMISE_STATES.pending || checkboxState !== true
          }
        >
          {t("submitButton")}
        </button>
      </form>
    </div>
  );
};

export default ConfigurationCodeForm;
