/* eslint-disable array-bracket-spacing */
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixedPdf as formatNumberToFixed } from 'utils';
import { PdfBlockHeading } from '../PdfBlockHeading';

import styles from './styles';

const PdfTotalInfo = ({
	quantity, m2, linearMeter, kg, materialCosts, shippingCosts, subtotal, vatCosts, total, showPrice,
	configuratorDataPricing,
}) => {
	const { t } = useTranslation('configurationTableHeading');

	// eslint-disable-next-line camelcase
	const { vat_percent, volume_discount_from_m2, construction_site_delivery_price_from } = configuratorDataPricing;
	const showIfPriceEnabled = (text) => (showPrice ? text : ' ');
	return (
		<View>
			<PdfBlockHeading
				title={t('totalCart')}
				iconName="equals"
			/>
			<View style={styles.totalInfoTable}>
				<View style={styles.row}>
					{/* Row 0: ... matLbl, matCosts */}
					<View style={[styles.col1, styles.cell]} />
					<View style={[styles.col2, styles.cell]} />
					<View style={[styles.col3, styles.cell]} />
					<View style={[styles.col4, styles.cell]} />
					<View style={[styles.col5, styles.cell]} />
					<View style={[styles.col6, styles.cell]} />
					<View style={[styles.col7, styles.cell]}>
						<Text>{showIfPriceEnabled(t('subtotal'))}</Text>
					</View>
					<View style={[styles.col8, styles.cell, styles.ship]}>
						<Text>{formatNumberToFixed(materialCosts)}</Text>
					</View>
					<View style={[styles.col9, styles.cell]} />
				</View>

				<View style={styles.row}>
					{/* Row 1: ... subtotal_lbl subtotal */}
					<View style={[styles.col1, styles.cell]} />
					<View style={[styles.col2, styles.cell]} />
					<View style={[styles.col3, styles.cell]} />
					<View style={[styles.col4, styles.cell]} />
					<View style={[styles.col5, styles.cell]} />
					<View style={[styles.col6, styles.cell]} />
					<View style={[styles.col7, styles.cell]}>
						<Text>{showIfPriceEnabled(t('lsva'))}</Text>
					</View>
					<View style={[styles.col8, styles.cell, styles.ship, styles.ship]}>
						<Text>{formatNumberToFixed(shippingCosts)}</Text>
					</View>
					<View style={[styles.col9, styles.cell]} />
				</View>

				<View style={styles.row}>
					{/* Row 2: total_txt empty empty empty empty subtotal_lbl subtotal */}
					<View style={[styles.col1, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.total_txt, styles.total_txtTop]} />
					<View style={[styles.col2, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.empty]} />
					<View style={[styles.col3, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.empty]} />
					<View style={[styles.col4, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.empty]} />
					<View style={[styles.col5, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.empty]} />
					<View style={[styles.col6, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.empty]} />
					<View style={[styles.col7, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.subtotal_lbl]}>
						<Text>{showIfPriceEnabled(t('subtotal'))}</Text>
					</View>
					<View style={[styles.col8, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin, styles.subtotal]}>
						<Text>{formatNumberToFixed(subtotal)}</Text>
					</View>
					<View style={[styles.col9, styles.cell, styles.greyGridCell, styles.shrinkBottomMargin]} />
				</View>

				<View style={styles.row}>
					{/* Row 3: total_txt qty qty_unit m2 m2_unit vat_lbl vatCosts */}
					<View style={[styles.col1, styles.cell, styles.col1, styles.total_txt]}>
						<Text>{t('blocktotal')}</Text>
					</View>
					<View style={[styles.col2, styles.cell, styles.greyGridCell, styles.greyGridCell, styles.empty]} />
					<View style={[styles.col3, styles.cell, styles.greyGridCell, styles.qty]}>
						<Text>{quantity}</Text>
					</View>
					<View style={[styles.col4, styles.cell, styles.greyGridCell, styles.qty_unit]}>
						<Text>{t('pc')}</Text>
					</View>
					<View style={[styles.col5, styles.cell, styles.greyGridCell, styles.m2]}>
						<Text>{formatNumberToFixed(m2)}</Text>
					</View>
					<View style={[styles.col6, styles.cell, styles.greyGridCell, styles.m2_unit]}>
						<Text>m²</Text>
					</View>
					<View style={[styles.col7, styles.cell, styles.greyGridCell, styles.vat_lbl]}>
						{/* eslint-disable-next-line react/jsx-one-expression-per-line */}
						<Text>{showIfPriceEnabled(`${t('vat')} (${formatNumberToFixed(vat_percent)}%)`)}</Text>
					</View>
					<View style={[styles.col8, styles.cell, styles.greyGridCell, styles.vatCosts]}>
						<Text>{formatNumberToFixed(vatCosts)}</Text>
					</View>
					<View style={[styles.col9, styles.cell, styles.greyGridCell]} />
				</View>

				<View style={styles.row}>
					{/* Row 4: total_txt kg kg_unit lfm lfm_unit total_lbl total */}
					<View style={[styles.col1, styles.cell, styles.shrinkTopMargin, styles.total_txt, styles.total_txtBottom]} />
					<View style={[styles.col2, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.empty]} />
					<View style={[styles.col3, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.kg]}>
						<Text>{formatNumberToFixed(kg, 0)}</Text>
					</View>
					<View style={[styles.col4, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.kg_unit]}>
						<Text>{t('kg')}</Text>
					</View>
					<View style={[styles.col5, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.lfm]}>
						<Text>{formatNumberToFixed(linearMeter)}</Text>
					</View>
					<View style={[styles.col6, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.lfm_unit]}>
						<Text>{t('linearMeterAbbrev')}</Text>
					</View>
					<View style={[styles.col7, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.total_lbl]}>
						<Text>{showIfPriceEnabled(t('totalPriceChf'))}</Text>
					</View>
					<View style={[styles.col8, styles.cell, styles.greyGridCell, styles.shrinkTopMargin, styles.total]}>
						<Text>{showIfPriceEnabled(formatNumberToFixed(total))}</Text>
					</View>
					<View style={[styles.col9, styles.cell, styles.greyGridCell, styles.shrinkTopMargin]} />
				</View>
			</View>
			<View style={styles.totalInfoMsg}>
				<Text style={styles.totalInfoVolumeDiscount}>
					{
						// eslint-disable-next-line camelcase
						(m2 >= volume_discount_from_m2) && (
							t('volumeDiscountFmt').replace('{}', volume_discount_from_m2)
						)
					}
				</Text>
				<Text style={styles.totalInfoDelivery}>{t('constructionSiteDeliveryFmt').replace('{}', construction_site_delivery_price_from)}</Text>
			</View>
		</View>
	);
};

export default PdfTotalInfo;
