/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import { pdf } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import 'react-datepicker/dist/react-datepicker.css';
import { CONFIGURATIONS_API } from "api";
import { ConfigurationBlock } from "components/ConfigurationBlock";
import BackButton from "components/Buttons/BackButton";
import { BlockHeading } from "components/BlockHeading";
import { HighlightedDatePicker } from "components/Form";
import { TermsBlock } from "components/TermsBlock";
import { OrderConfirmationSection } from "components/OrderConfirmationSection";
import { SaveAsSection } from "components/SaveAsSection";
import { resetFilters } from "components/ThreeJs/assets/main";
import { PdfConfigurationsDocument } from "components/PdfComponents";
import { getHtmlEmailSignature } from "components/Form/EmailNotifications";
import { AppDataContext } from "context/appData";
import { AuthContext } from "context/auth";
import { ConfigurationDataContext, GlassStructureContext } from "context/configuration";
import { CONFIGURATION_TYPE, PROMISE_STATES } from "utils/constant";
import { cloneObjectWithoutReference, convertFileToBase64, dateToLocaleString } from "utils/index";
import i18n from "utils/i18n";
import ToastifyHandler from "utils/ToastifyHandler";
import userInfo from "utils/userInfo";

const SummaryPage = () => {
  const { t } = useTranslation(['requestOrOrderPage', 'firebaseErrorMessages']);
  const navigate = useNavigate();
  const { configuratorData, themeSettings } = useContext(AppDataContext);
  const { guestUser, currentUser, currentUserAdditionalData, setGuestUser } =
    useContext(AuthContext);
  const {
    configuration,
    setConfiguration,
    totalGlassSizes,
    totalGlassSizesSum,
    oversizedGlassFields,
  } = useContext(ConfigurationDataContext);
  const { uiHandlerRef } = useContext(GlassStructureContext);
  const [sendingEmailStatus, setSendingEmailStatus] = useState(
    PROMISE_STATES.default
  );
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isTermsCheckedMissing, setIsTermsCheckedMissing] = useState(false);
  const [isOrderDateMissing, setIsOrderDateMissing] = useState(false);
  const [confirmationPageData, setConfirmationPageData] = useState(null);
  const [selectedQuoteDate, setSelectedQuoteDate] = useState(null);
  const [selectedOrderDeliveryDate, setSelectedOrderDeliveryDate] = useState(null);
  const notificationsHandler = useRef(new ToastifyHandler());
  const deOrderType = (orderType) => t(orderType, { ns: 'email', lng: 'de' });
  const dueDateByType = (orderType) => (orderType === CONFIGURATION_TYPE.order
    ? configuration.deliveryByDate
    : configuration.quoteByDate);
  const deDueDateTextByType = (orderType) => {
    switch (orderType) {
      case CONFIGURATION_TYPE.order:
        return t('orderDeliveryDateFmt', { ns: 'pdfPage', lng: 'de' }).replace('{}', dateToLocaleString(configuration.deliveryByDate));
      case CONFIGURATION_TYPE.request:
        return t('requestDueDateFmt', { ns: 'pdfPage', lng: 'de' }).replace('{}', dateToLocaleString(configuration.quoteByDate));
      default:
        return null;
    }
  };
  const toEmail = (orderType) => (orderType === CONFIGURATION_TYPE.order
    ? 'order@cdr.ch'
    : 'sub@cdr.ch');

  useEffect(() => {
    if (configuration) {
      if (configuration.quoteByDate) {
        setSelectedQuoteDate(configuration.quoteByDate);
      }
      if (configuration.deliveryByDate) {
        setSelectedOrderDeliveryDate(configuration.deliveryByDate);
      }
    } else {
      const timer = setTimeout(() => {
        navigate('/');
      }, 5000);

      return () => { clearTimeout(timer); };
    }
    return () => { };
  }, [configuration, navigate]);

  useEffect(() => {
    if (isTermsChecked && isTermsCheckedMissing) {
      setIsTermsCheckedMissing(false);
    }
  }, [isTermsChecked, isTermsCheckedMissing]);

  const createClientEmailData = async (configurationPdfFileInBase64, attachments, orderType, reference) => {
    const orderTypeDE = deOrderType(orderType);
    let person = null;

    if (currentUser) {
      const { name, lastName, gender, email } = currentUserAdditionalData;
      person = {
        email,
        firstName: name,
        lastName,
        fullName: `${name} ${lastName}`,
        gender,
      };
    } else if (guestUser) {
      const { userName, email } = guestUser;
      person = {
        email,
        firstName: userName,
        lastName: '',
        fullName: userName,
        gender: 'div'
      };
    } else {
      return null;
    }

    const salutation = t(`email:salutationFmt_${person.gender}`).replace(/\{(\w+)\}/g, (_match, key) => person[key] || '');
    const html_body = t(`email:confirmationBodyFmt_${orderType}`)
      .replace('{salutation}', salutation)
      .replace('{signature}', await getHtmlEmailSignature({ configuratorData, themeSettings, t }));
    const subjectReference = reference ? `: ${reference}` : '';
    const subject = `${t(`email:confirmationSubject_${orderType}`)}${subjectReference}`;
    const reply_to = toEmail(orderType);
    const from_email = 'configurator@cdr.ch';

    return {
      from_email,
      reply_to,
      to_name: person.fullName,
      user_email: person.email,
      order_type_de: orderTypeDE,
      order_date: new Date(configuration.publishedAt).toLocaleString("de-CH"),
      subject,
      html_body,
      configuration_filename: 'Configuration.pdf',
      configuration_data: configurationPdfFileInBase64,
      num_attachments: Object.keys(attachments.files).length,
      ...attachments.files,
      ...attachments.names,
    };
  };

  const createAdminEmailData = (configurationPdfFileInBase64, attachments, orderType, reference) => {
    let ret = null;
    if (currentUser) {
      const { name: companyName, VATNumber, tel, zip, city, address } = currentUserAdditionalData.company;
      ret = {
        client_company_name: companyName,
        client_company_vat_number: VATNumber,
        client_email: currentUserAdditionalData.email,
        client_first_name: currentUserAdditionalData.name,
        client_last_name: currentUserAdditionalData.lastName,
        client_company_street: address,
        client_company_zip: zip,
        client_company_city: city,
        client_company_phone: tel,
        guest_order: 'Nein',
      };
    } else if (guestUser) {
      ret = {
        client_company_name: guestUser.companyName,
        client_company_vat_number: guestUser.companyVATNumber,
        client_email: guestUser.email,
        client_first_name: guestUser.userName,
        client_last_name: '',
        client_company_street: guestUser.companyStreet,
        client_company_zip: guestUser.companyZIP,
        client_company_city: guestUser.companyCity,
        client_company_phone: guestUser.companyPhone,
        guest_order: 'Ja',
      };
    } else {
      return null;
    }

    return {
      ...ret,
      reference,
      client_lang: i18n.language.toUpperCase(),
      from_email: 'configurator@cdr.ch',
      to_email: toEmail(orderType),
      order_type_de: deOrderType(orderType, { ns: 'email' }),
      due_date: deDueDateTextByType(orderType),
      order_date: new Date(configuration.publishedAt).toLocaleString("de-CH"),
      configuration_filename: 'Configuration.pdf',
      configuration_data: configurationPdfFileInBase64,
      num_attachments: Object.keys(attachments.files).length,
      ...attachments.files,
      ...attachments.names,
    };
  };

  const sendEmail = async (
    updatedConfiguration,
    configurationPdfFileInBase64,
    attachmentList,
    orderType
  ) => {
    if (!configurationPdfFileInBase64) return;
    if (!updatedConfiguration) return;

    notificationsHandler.current.pending(
      t("notifications:emailSendingInProgress")
    );
    setSendingEmailStatus(PROMISE_STATES.pending);

    const attachments = attachmentList.reduce((acc, curr, index) => {
      const attachmentKey = `file${index + 1}`;
      acc.files[attachmentKey] = curr.url;
      acc.names[`${attachmentKey}_name`] = curr.name;
      return acc;
    }, { files: {}, names: {} });

    const clientEmail = await createClientEmailData(
      configurationPdfFileInBase64,
      attachments,
      orderType,
      updatedConfiguration.reference || updatedConfiguration.buildingReference,
    );
    const adminEmail = createAdminEmailData(
      configurationPdfFileInBase64,
      attachments,
      orderType,
      updatedConfiguration.reference || updatedConfiguration.buildingReference,
    );

    const promises = [];
    promises.push(emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_ADMIN_TEMPLATE_ID,
      adminEmail,
      process.env.REACT_APP_EMAILJS_USER_ID,
    ));
    promises.push(emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_USER_TEMPLATE_ID,
      clientEmail,
      process.env.REACT_APP_EMAILJS_USER_ID,
    ));
    promises.push(CONFIGURATIONS_API.addNewConfiguration(updatedConfiguration));
    const [adminEmailRes, clientEmailRes, configRes] = await Promise.allSettled(promises);
    if (adminEmailRes.status === 'fulfilled' && clientEmailRes.status === 'fulfilled' && configRes.status === 'fulfilled') {
      notificationsHandler.current.success(
        t(orderType === CONFIGURATION_TYPE.order ? "notifications:orderSuccessful" : "notifications:quoteSuccessful")
      );
    } else {
      notificationsHandler.current.rejected(t("notifications:anErrorHasOccurred"));
      setSendingEmailStatus(PROMISE_STATES.rejected);
      if (adminEmailRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error sending admin email");
      }
      if (clientEmailRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error sending client email");
      }
      if (configRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error updating configuration");
      }
      return;
    }

    setConfirmationPageData({ orderType, email: clientEmail.user_email });
    setSendingEmailStatus(PROMISE_STATES.success);

    setConfiguration(null);
    if (guestUser) {
      setGuestUser(null);
    }
    resetFilters();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const orderType = event.target.id === 'form-request' ? CONFIGURATION_TYPE.request : CONFIGURATION_TYPE.order;
    // Check GTC first
    if (!isTermsChecked || (orderType === 'order' && !selectedOrderDeliveryDate)) {
      if (!isTermsChecked) {
        setIsTermsCheckedMissing(true);
      }
      if (orderType === 'order' && !selectedOrderDeliveryDate) {
        if (!isOrderDateMissing) {
          setIsOrderDateMissing(true);
        }
      } else if (isOrderDateMissing) {
        setIsOrderDateMissing(false);
      }
      return;
    }

    const dueDate = dueDateByType(orderType);
    const clonedConfiguration = cloneObjectWithoutReference(configuration);
    const preparedOrderType = orderType || CONFIGURATION_TYPE.request;
    clonedConfiguration.type = preparedOrderType;

    const getConfigurationPdfFile = async () => {
      const blob = await pdf(
        <PdfConfigurationsDocument
          configuration={clonedConfiguration}
          contactInfo={themeSettings.contact_info}
          customerInfo={userInfo({ guestUser, currentUser, currentUserAdditionalData, companyInfo: currentUserAdditionalData?.company })}
          totalValues={totalGlassSizes}
          totalValuesSum={totalGlassSizesSum}
          oversizedGlassFields={oversizedGlassFields}
          showPrice={currentUserAdditionalData?.company?.showPrices || false}
          configuratorDataPricing={configuratorData.pricing}
          dueDate={dueDate}
          uiHandlerRef={uiHandlerRef}
        />
      ).toBlob();
      const file = new File([blob], "ConfigurationPDF.pdf");

      return file;
    };

    try {
      const configurationPdfFile = await getConfigurationPdfFile();
      const configurationPdfFileInBase64 = await convertFileToBase64(
        configurationPdfFile
      );
      const attachments = clonedConfiguration.uploadedDocuments;

      sendEmail(
        clonedConfiguration,
        configurationPdfFileInBase64,
        attachments,
        preparedOrderType
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  if (sendingEmailStatus === PROMISE_STATES.success && confirmationPageData) {
    return (
      <OrderConfirmationSection {...confirmationPageData} />
    );
  }

  if (!configuration || !(guestUser || currentUser)) {
    return (
      <section className="section">
        <div className="section__in">
          <div className="section__block">
            <h1 className="section__title section__center">
              {t('cartPage:cartIsEmpty')}
            </h1>
            <div className="section__center">
              <BackButton
                buttonText={t('orderConfirmationPage:backToStart')}
                navigateToUrl="/"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="section request_or_order_page">
        <div className="section__in">
          <BlockHeading
            title={t("commonAppValues:configuration")}
            iconName="config_code"
          />
          <ConfigurationBlock
            reference={configuration.reference}
            buildingReference={configuration.buildingReference}
            amountGlassTypes={configuration.amountGlassTypes}
            created={configuration.created}
            lastEdited={configuration.lastEdited}
            code={configuration.code}
            isOrderPage
          />
          <TermsBlock isTermsCheckedMissing={isTermsCheckedMissing} setIsTermsChecked={setIsTermsChecked} />
          <div className="request_or_order_page__buttons">

            <form id="form-request" onSubmit={handleSubmit}>
              <div className="request_or_order_page__rfq_order_stack">
                <HighlightedDatePicker
                  placeholderText={t('preferredQuoteDate')}
                  selected={selectedQuoteDate}
                  onChange={(quoteDate) => {
                    const clonedConfiguration = cloneObjectWithoutReference(configuration);
                    clonedConfiguration.quoteByDate = quoteDate ? quoteDate.toISOString() : undefined;
                    setConfiguration(clonedConfiguration);
                    setSelectedQuoteDate(quoteDate);
                  }}
                />
                <button
                  className="btn_v3"
                  type="submit"
                  disabled={sendingEmailStatus === PROMISE_STATES.pending}
                >
                  {t("doRequest")}
                </button>
              </div>
            </form>
            <form id="form-order" onSubmit={handleSubmit}>
              <div className="request_or_order_page__rfq_order_stack">
                <HighlightedDatePicker
                  className={isOrderDateMissing ? 'terms_block--missing' : undefined}
                  placeholderText={t('preferredOrderDeliveryDate')}
                  selected={selectedOrderDeliveryDate}
                  onChange={(orderDate) => {
                    if (isOrderDateMissing) {
                      setIsOrderDateMissing(false);
                    }
                    const clonedConfiguration = cloneObjectWithoutReference(configuration);
                    clonedConfiguration.deliveryByDate = orderDate ? orderDate.toISOString() : undefined;
                    setConfiguration(clonedConfiguration);
                    setSelectedOrderDeliveryDate(orderDate);
                  }}
                />
                <button
                  className={"btn_v3".concat(!isTermsChecked || !selectedOrderDeliveryDate ? " disabled-button" : "")}
                  type="submit"
                  disabled={sendingEmailStatus === PROMISE_STATES.pending}
                >
                  {t("doOrder")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <SaveAsSection />
    </>
  );
};

export default SummaryPage;
