import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from '@react-pdf/renderer';

import styles from '../styles';

const PdfGlassConfigDetailItem = ({
	title,
	categoryLabel,
	selected,
	secondLayoutOption = false,
	accentMod = false,
	isFirstItem = false,
}) => {
	const { t } = useTranslation('configurationCategory');

	const getDetailItemStyle = () => {
		if (secondLayoutOption) {
			return styles.glassConfigItemShortState;
		}

		return styles.glassConfigItem;
	};

	const getDetailItemInStyle = () => {
		if (secondLayoutOption) {
			return styles.glassConfigBlockSmState;
		}

		if (accentMod) {
			return {
				...styles.glassConfigBlockLgState,
				...styles.glassConfigBLockLgAccentState,
			};
		}

		if (isFirstItem) {
			return {
				...styles.glassConfigBlockLgState,
				...styles.glassConfigBLockLgFirstItem,
			};
		}

		return styles.glassConfigBlockLgState;
	};

	return (
		<View style={getDetailItemStyle()}>
			<View style={getDetailItemInStyle()}>
				<View>
					{title ? (
						<Text style={styles.glassConfigBlockTitle} wrap>{title}</Text>
					) : null}
					{categoryLabel ? (
						<Text style={styles.glassConfigBlockType} wrap>{t(categoryLabel)}</Text>
					) : null}
					{selected ? (
						<Text style={styles.glassConfigBlockSelected} wrap>{selected}</Text>
					) : null}
				</View>
			</View>
		</View>
	);
};

export default PdfGlassConfigDetailItem;
