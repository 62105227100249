import { firebaseDB, firebaseFunctions } from 'utils/firebase';
import {
	getDoc,
	doc,
	query,
	collection,
	getDocs,
	where,
	updateDoc,
	getDocFromCache,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const COLLECTION_NAME = 'users';

const addNewUserAndCompany = async (userUid, user, company) => {
	const createNewUserAndCompany = httpsCallable(
		firebaseFunctions,
		"createNewUserAndCompany",
	);
	const res = await createNewUserAndCompany({ userUid, user, company });
	return res;
};

const updateUserData = async (userUid, userData) => {
	if (!userUid) return null;
	if (!userData) return null;

	const userRef = doc(firebaseDB, COLLECTION_NAME, userUid);
	const res = await updateDoc(userRef, userData);

	return res;
};

const getUser = async id => {
	if (!id) return null;
	const docObj = doc(firebaseDB, COLLECTION_NAME, id);

	// try a cached query first
	try {
		const snap = await getDocFromCache(docObj);
		if (snap.exists()) {
			return snap.data();
		}
	} catch (e) {
		const snap = await getDoc(docObj);
		if (snap.exists()) {
			return snap.data();
		}
	}

	return null;
};

const getUsersFromCompany = async (companyId) => {
	if (!companyId) return null;

	const companyUsersQuery = query(collection(firebaseDB, COLLECTION_NAME), where('companyId', '==', companyId));

	const querySnapshot = await getDocs(companyUsersQuery);

	const res = [];

	querySnapshot.forEach((docSnap) => {
		res.push(docSnap.data());
	});

	return res;
};

const USERS_API = {
	addNewUserAndCompany,
	updateUserData,
	getUser,
	getUsersFromCompany,
};

export default USERS_API;
