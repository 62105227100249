import React from 'react';

import { View, Image } from '@react-pdf/renderer';
import GlassPreviewImage from 'i/glass.jpg';
import PdfGlassConfigDetailFirst from './partials/PdfGlassConfigDetailFirst';
import PdfGlassConfigDetailSecond from './partials/PdfGlassConfigDetailSecond';
import PdfGlassConfigTable from './partials/PdfGlassConfigTable';
import { PdfBlockHeading } from '../PdfBlockHeading';

import styles from './styles';

const PdfGlassConfig = ({
	glassData,
	glassIndex,
	isGlassDocument, // Whether it's a single glass (true) or the whole shopping cart (false)
	totalValues,
	oversizedGlassFields,
	showPrice,
	datasheets,
}) => {
	const getGlassConfigImageStyle = () => {
		return isGlassDocument ? styles.glassConfigImageWlgState : styles.glassConfigImageW;
	};

	const ds = isGlassDocument ? datasheets[0] : datasheets[glassIndex - 1];
	return (
		<View style={styles.glassConfig} wrap={false}>
			<View style={getGlassConfigImageStyle()}>
				<Image src={glassData.screenshot ?? GlassPreviewImage} style={styles.glassConfigImage} />
			</View>
			<PdfBlockHeading
				title={glassData.fireResistant}
				blockIndex={!isGlassDocument ? glassIndex : null}
				iconName={isGlassDocument ? 'gear' : null}
				datasheetUrl={ds}
			/>
			<PdfGlassConfigDetailFirst
				configDetails={glassData}
			/>
			<PdfGlassConfigDetailSecond
				configDetails={glassData}
				showPrice={showPrice}
			/>
			<PdfGlassConfigTable
				configTableData={glassData}
				totalValues={totalValues}
				oversizedGlassFields={oversizedGlassFields}
				glassIndex={glassIndex}
				showPrice={showPrice}
			/>
		</View>
	);
};

export default PdfGlassConfig;
