export default class GlassChangeTracker {

    static GlassStructureChanged = class { }
    static NoDefaultSpacerOrGasError = class { }
    static NoDefaultGlassError = class {
        constructor(glassLabel) {
            this.glassLabel = glassLabel;
        }
    }

    constructor(uiHandler) {
        this._uiHandler = uiHandler;
        this._structure = uiHandler.structure;
        this._userFilters = {}; // like _filters but only carries filters set directly by the user, thus not reset
        this._snapshot = null;
        this.reset(false, true);
    }

    reset(take_snapshot = true, resetUserFilters = false) {
        this._glass = {
            'frg': undefined,
            'mid': undefined,
            'out': undefined,
        }
        this._keep_current_glass = {
            'frg': undefined,
            'mid': undefined,
            'out': undefined,
        }
        if (resetUserFilters) {
            this._spacers = null; // int: >0. Unset is 0, unchanged is null
            this._gas = null; // str: argon, krypton, mixedGas. Unset is 0, unchanged is null
        }
        this._resetAll = false;
        this._promptUserReset = false;
        this._refilterAll = false;
        this._triggeringFilter = null; // The filter that triggered this change, if any
        this._filters = {};
        this._refreshSpacerAndGasUvalues = false;
        if (take_snapshot) {
            this._snapshot = {
                dbView: this._uiHandler.dbView ? this._uiHandler.dbView.clone() : null,
                userFilters: { ...this._userFilters },
                structure: this._structure.getSnapshot(),
                filterSnapshot: this._uiHandler.filterHandler.getFilterSnapshot(),
            };
        }
    }

    revertToSnapshot() {
        this._uiHandler.dbView = this._snapshot.dbView;
        this._structure.applySnapshot(this._snapshot.structure);
        this._uiHandler.filterHandler.applyFilterSnapshot(this._snapshot.filterSnapshot, this._structure.getGlassStructure());
        this._userFilters = { ...this._snapshot.userFilters };
        this.reset(false, true);
    }

    _getSnapshotGlassByLabel(label) {
        if (label === 'frg') { return this._snapshot.structure.fireResistantGlass; }
        if (label === 'mid') { return this._snapshot.structure.middleGlass; }
        if (label === 'out') { return this._snapshot.structure.outerGlass; }
        console.error(`No such label ${label}`);
    }

    /**
     * Returns the structure of the glass:
     * '1-glazed', '2-glazed', '3-glazed'
     */
    getGlassStructure() {
        if (this._glass['mid'] || this._glass['mid'] === undefined && this._structure.middleGlass) { return '3-glazed'; }
        if (this._glass['out'] || this._glass['out'] === undefined && this._structure.outerGlass) { return '2-glazed'; }
        return '1-glazed';
    }

    getPreviousGlassStructure() {
        return this._snapshot.structure.getGlassStructure();
    }

    didFilterChange(filter, oldNewObj) {
        let previousFilter = this._snapshot.filterSnapshot[filter.filterName];
        let currentFilter = filter.selectedFilter;
        if (oldNewObj) {
            oldNewObj['old'] = previousFilter;
            oldNewObj['new'] = currentFilter;
        }
        return (filter.selectedFilter !== previousFilter);
    }

    /// Change to a different glass structure (e.g. 1->3-glazed or 3->2-glazed)
    setGlassStructure(struct) {
        let oldGlassStruct = this._snapshot.structure.getGlassStructure();
        if (struct === oldGlassStruct) {
            return;
        }

        let structureFilter = this._uiHandler.filterHandler.structureFilter;
        if (structureFilter.selectedFilter && this._triggeringFilter !== structureFilter) {
            this.resetFilter(structureFilter);
        }

        if (struct === '3-glazed') {
            // CDR: When switching to 3-glazed the middle glass must be 4mm extrawhite
            // We thus reset the white glass filter when the structure is switching to 3-glazed
            let whiteGlassFilter = this._uiHandler.filterHandler.whiteGlassFilter;
            if (whiteGlassFilter.selectedFilter === "unchecked") {
                this.resetFilter(whiteGlassFilter);
            }
        }

        if (oldGlassStruct === '1-glazed') {
            this.setFirstGlass('out');
            if (struct === '3-glazed') {
                this.setFirstGlass('mid');
            }

        } else if (oldGlassStruct === '2-glazed') {
            if (struct === '1-glazed') {
                this.setGlass('out', null);
            } else if (struct === '3-glazed') {
                this.setFirstGlass('mid');
            }

        } else if (oldGlassStruct === '3-glazed') {
            this.setGlass('mid', null);
            if (struct === '1-glazed') {
                this.setGlass('out', null);
            }
        }
    }

    setGlass(label, glass) {
        this._glass[label] = glass;
    }

    setFirstGlass(label, try_keep_current = true) {
        if (this._glass[label]) {
            if (this._glass[label] !== true) {
                console.warn(`Already a glass set on ${label}`);
            }
        } else {
            this._glass[label] = true;
            this._keep_current_glass[label] = try_keep_current;
        }
    }

    getChangedGlass(label) {
        let glass = this._glass[label];
        if (glass === true) {
            let currentGlass = this._getSnapshotGlassByLabel(label);
            if (this._keep_current_glass[label] && currentGlass) {
                // check if current glass is still in filter-range before reassigning
                glass = this._uiHandler.dbView[label].find(g => g === currentGlass);
                if (glass) {
                    return undefined; // no change to existing glass
                }
            }
            if (this._uiHandler.dbView[label].length < 1) {
                throw new GlassChangeTracker.NoDefaultGlassError(label);
            }
            let newGlass = this._uiHandler.dbView[label][0];
            if (currentGlass && newGlass === currentGlass) {
                return undefined;
            }
            return newGlass;
        }
        if (glass === undefined) { // No request to change the glass. Check if it got filtered out.
            let currentGlass = this._snapshot.structure.getGlassByLabel(label);
            if (currentGlass) {
                glass = this._uiHandler.dbView[label].find(g => g === currentGlass);
                if (glass) {
                    return undefined; // no change to existing glass
                } else {
                    if (this._uiHandler.dbView[label].length < 1) {
                        throw new GlassChangeTracker.NoDefaultGlassError(label);
                    }
                    return this._uiHandler.dbView[label][0];
                }
            }
        }
        return glass;
    }

    setSpacer(width) {
        this._spacers = width;
        if (width === 0) {
            this.setGlassStructure('1-glazed');
        }
    }

    /// Set gas: (valid) str
    /// Unset gas: '' (empty str)
    setGas(gas) {
        this._gas = gas;
        if (gas === 0) {
            this.setGlassStructure('1-glazed');
        }
    }

    setFirstGasAndSpacer() {
        if (this._spacers) {
            // console.debug("Spacers already set");
        } else {
            this._spacers = true;
        }
        if (this._gas) {
            // console.debug("Gas already set");
        } else {
            this._gas = true;
        }
    }

    getChangedGasAndSpacer() {
        let struct = this.getGlassStructure();
        let res = { gas: null, spacer: null }; // unchanged configuration, 0 is reset
        let currentSpacer = this._snapshot.structure.spacerOutside.thickness;
        let currentGas = this._snapshot.structure.gasOutside;

        if (this._spacers === null && this._gas === null) {
            return res;
        }
        if (this._spacers === 0 && this._gas === 0) {
            // 1-glazed structure resets spacers and gas
            return { gas: 0, spacer: 0 };
        }

        if (this._uiHandler.dbView['uValue'][struct].length === 0) {
            throw new GlassChangeTracker.NoDefaultSpacerOrGasError();
        }

        if (!(this._spacers === true || this._gas === true)) {
            // one is fixed e.g. 14mm and one is already set (thus null in the changetracker)
            if (this._spacers === null) {
                this._spacers = currentSpacer;
            }
            if (this._gas === null) {
                this._gas = currentGas;
            }
        }

        if (this._spacers === true && this._gas === true) {
            // Gas and spacers are flexible
            if (currentSpacer && currentGas) {
                // Do not include gas matching to ensure we downgrade appropriately if an option is available
                let dbo = this._uiHandler.dbView['uValue'][struct].find(o => o.spacer1 === currentSpacer /* && o.gas === currentGas */);
                if (dbo) {
                    // Return the gas and spacer to force an update if there's a structure change
                    if (this._snapshot.structure.getGlassStructure() !== struct) {
                        res.spacer = dbo.spacer1;
                        res.gas = dbo.gas;
                        return res;
                    } else {
                        res.spacer = null; // no change to current spacer and gas
                        res.gas = (currentGas === dbo.gas ? null : dbo.gas);
                        return res;
                    }
                }
            }
            res.spacer = this._uiHandler.dbView['uValue'][struct][0].spacer1;
            res.gas = this._uiHandler.dbView['uValue'][struct][0].gas;
            return res;

        } else if (this._spacers === true) {
            // Gas is fixed, spacers are flexible
            let dbo = this._uiHandler.dbView['uValue'][struct].find(o => o.gas === this._gas);
            if (dbo) {
                // Return the gas and spacer to force an update if there's a structure change
                if (this._snapshot.structure.getGlassStructure() !== struct) {
                    res.spacer = dbo.spacer1;
                    res.gas = dbo.gas;
                    return res;
                } else {
                    res.spacer = dbo.spacer1 === currentSpacer ? null : dbo.spacer1;
                    res.gas = currentGas === this._gas ? null : this._gas;
                    return res;
                }
            }
            res.spacer = this._uiHandler.dbView['uValue'][struct][0].spacer1;
            res.gas = this._uiHandler.dbView['uValue'][struct][0].gas;
            return res;

        } else if (this._gas === true) {
            // Spacers are fixed, gas is flexible
            let dbo = this._uiHandler.dbView['uValue'][struct].find(o => o.spacer1 === this._spacers);
            if (dbo) {
                // Return the gas and spacer to force an update if there's a structure change
                if (this._snapshot.structure.getGlassStructure() !== struct) {
                    res.spacer = dbo.spacer1;
                    res.gas = dbo.gas;
                    return res;
                } else {
                    res.spacer = currentSpacer === this._spacers ? null : this._spacers;
                    res.gas = dbo.gas === currentGas ? null : dbo.gas;
                    return res;
                }
            }
            res.spacer = this._uiHandler.dbView['uValue'][struct][0].spacer1;
            res.gas = this._uiHandler.dbView['uValue'][struct][0].gas;
            return res;

        } else {
            // Both gas and spacers are fixed
            let dbo = this._uiHandler.dbView['uValue'][struct].find(o => o.spacer1 === currentSpacer && o.gas === currentGas);
            if (!dbo) {
                console.error("Chosen Gas and Spacer configuration is filtered out");
            }
            res.spacer = currentSpacer === this._spacers ? null : this._spacers;
            res.gas = currentGas === this._gas ? null : this._gas;

            if (struct === '3-glazed') {
                if (!res.spacer && this._snapshot.structure.spacerMiddle.thickness === 0) res.spacer = currentSpacer;
                if (!res.gas && !this._snapshot.structure.gasMiddle) res.gas = currentGas;
            }
            return res;
        }
    }

    setRefreshSpacerAndGasUValues() {
        this._refreshSpacerAndGasUvalues = true;
    }

    getRefreshSpacerAndGasUValues() {
        return this._refreshSpacerAndGasUvalues;
    }

    isChangedStructure() {
        return this._snapshot.structure.getGlassStructure() !== this.getGlassStructure();
    }

    isMonoIsoStructureChange() {
        let oldStruct = this._snapshot.structure.getGlassStructure();
        let newStruct = this.getGlassStructure();
        return ((oldStruct === '1-glazed' && newStruct !== '1-glazed') ||
            oldStruct !== '1-glazed' && newStruct === '1-glazed');
    }

    isInterIsoStructureChange() {
        let oldStruct = this._snapshot.structure.getGlassStructure();
        let newStruct = this.getGlassStructure();
        return ((oldStruct === '2-glazed' && newStruct === '3-glazed') ||
            (oldStruct === '3-glazed' && newStruct === '2-glazed'));
    }

    setUserFilter(filter, refilterAll = false) {
        if (filter.selectedFilter === null) {
            this.resetFilter(filter);
            delete this._userFilters[filter.filterName];
        } else {
            this.setFilter(filter, refilterAll);
            this._userFilters[filter.filterName] = filter.selectedFilter;
        }
    }

    setFilter(filter, refilterAll = false) {
        // only set the filter if it's not already set to be reset
        if (this._filters[filter.filterName] !== false) {
            this._filters[filter.filterName] = true;
        }
        if (refilterAll) {
            this._refilterAll = true;
        }
    }

    setTriggeringFilter(filter) {
        this._triggeringFilter = filter;
    }

    getTriggeringFilter() {
        return this._triggeringFilter;
    }

    getRefilterAll() {
        return this._refilterAll;
    }

    resetFilter(filter, prompt_user = false) {
        this._filters[filter.filterName] = false;
        delete this._userFilters[filter.filterName];
        if (prompt_user) {
            this._promptUserReset = true;
        }
    }

    resetAllFilters(prompt_user = false) {
        this._uiHandler.filterHandler.getActiveFilters().map(f => this.resetFilter(f));
        this._resetAll = true;
        if (prompt_user) {
            this._promptUserReset = true;
        }
    }

    resetAllOtherFilters(filter_to_keep, prompt_user = false) {
        this._uiHandler.filterHandler.getActiveFilters().map(f => {
            if (f !== filter_to_keep) {
                this.resetFilter(f);
            }
        });
        if (prompt_user) {
            this._promptUserReset = true;
        }
    }

    getResetAllFilters() {
        return this._resetAll;
    }

    getPromptUserReset() {
        return this._promptUserReset;
    }

    getNewFilters() {
        return Object.entries(this._filters).filter(([k, v]) => v === true).map(([k, v]) => this._uiHandler.filterHandler.getFilterByName(k));
    }

    getResetFilters() {
        return Object.entries(this._filters).filter(([k, v]) => v === false).map(([k, v]) => this._uiHandler.filterHandler.getFilterByName(k));
    }

    getUserSetFilters() {
        return Object.keys(this._userFilters);
    }
}
