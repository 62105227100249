/* eslint-disable no-multi-spaces */
import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	// The container that replaces .total_info_table
	totalInfoTable: {
		position: 'relative',
		top: -20, // margin-top: -2rem but margin-top may cause issues.
		minHeight: 12, // avoid issues with empty nodes
		display: 'flex',
		flexDirection: 'column',
		fontSize: 10,
		textTransform: 'uppercase',
		lineHeight: 1.4,
		width: '100%',
	},

	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},

	// Total 5.5fr eq.
	col1: { width: '16.67%' }, // 1fr
	col2: { width: '25.00%' }, // 1.5fr
	col3: { width: '5.55%' }, // Auto width (shrinks if needed) ---
	col4: { width: '5.55%' }, // Auto width (shrinks if needed)  | all 3 about 1fr
	col5: { width: '8.33%' }, // Auto width (shrinks if needed)  ---
	col6: { width: '8.33%' }, // 0.5fr equivalent
	col7: { width: '22.24%' }, // 2fr
	col8: { width: '8.00%' }, // Auto width (shrinks if needed)
	col9: { width: '0.33%' }, // 0.05fr equivalent

	// Generic base cell (like .total_info__cell)
	cell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingHorizontal: 8, // ~0.5rem
		paddingVertical: 4, // ~0.25rem or 0.5rem—adjust as needed
	},

	greyGridCell: {
		backgroundColor: '#e6e6e6', // default cell bg
	},

	light: {
		backgroundColor: '#f0f0f0',
	},

	bold: {
		fontWeight: 'bold',
	},

	label: {
		fontWeight: 'normal',
	},
	value: {
		fontWeight: 'bold',
	},

	shrinkBottomMargin: {
		marginBottom: -1, // white line caused by subpixel alignment
	},
	shrinkTopMargin: {
		marginTop: -1, // white line caused by subpixel alignment
	},

	// Row 0: subtotal, material costs
	materialCosts: {
		fontWeight: 'bold',
		textAlign: 'right',
	},

	// Row 1: shipping label, shippingCosts
	ship: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},

	// Row 2: total_txt empty empty empty empty subtotal_lbl subtotal
	total_txt: {
		backgroundColor: '#456d86', // $blue_2
		color: '#fff',
		fontWeight: 700,
		textAlign: 'center',
		justifyContent: 'center',
	},
	total_txtTop: {
		borderTopLeftRadius: '2px',
	},
	empty: {
	},
	subtotal_lbl: {
		fontWeight: 'bold',
	},
	subtotal: {
		fontWeight: 'bold',
		textAlign: 'right',
		justifyContent: 'flex-end',
	},

	// Row 3: total_txt qty qty_unit m2 m2_unit vat_lbl vat
	qty: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},
	qty_unit: {
		fontWeight: 700,
	},
	m2: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},
	m2_unit: {
		fontWeight: 700,
	},
	vat_lbl: {
		fontWeight: 'normal',
	},
	vatCosts: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},

	// Row 4: total_txt kg kg_unit lfm lfm_unit total_lbl total
	total_txtBottom: {
		borderBottomLeftRadius: '2px',
	},
	kg: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},
	kg_unit: {
		fontWeight: 700,
	},
	lfm: {
		textAlign: 'right',
		justifyContent: 'flex-end',
	},
	lfm_unit: {
		fontWeight: 700,
	},
	total_lbl: {
		fontWeight: 'bold',
	},
	total: {
		fontWeight: 'bold',
		textAlign: 'right',
		justifyContent: 'flex-end',
	},

	totalInfoMsg: {
		marginTop: 8,
	},
	totalInfoVolumeDiscount: {
		alignItems: 'flex-end',
		textAlign: 'right',
		fontWeight: 'bold',
		fontSize: 10,
		color: '#721c24',
	},
	totalInfoDelivery: {
		marginTop: 4,
		alignItems: 'flex-end',
		textAlign: 'right',
		fontSize: 8,
	},

	/*
.total_info__volume_discount {
	text-align: left;
	font-weight: bold;
	font-size: 2rem;
	color: $red;
	padding-bottom: 1rem;
}
	*/
});

export default styles;
