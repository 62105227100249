import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumberToFixed } from 'utils';
import GlassConfigTotalCell from './GlassConfigTotalCell';

const GlassConfigTotal = ({
	quantity, m2, linearMeter, kg, price, showPrice,
}) => {
	const { t } = useTranslation('configurationTableHeading');

	return (
		<div className="glass_config_table__row glass_config_table__row--total_mod">
			<span className="glass_config_table__cell_title glass_config_table__cell_title--color_mod_1">
				<GlassConfigTotalCell label={t('totalGlassType')} value />
			</span>
			<GlassConfigTotalCell label={t('quantity')} value={formatNumberToFixed(quantity, 0)} />
			<GlassConfigTotalCell
				label={(
					<>
						m
						<sup>2</sup>
					</>
				)}
				value={formatNumberToFixed(m2)}
			/>
			<GlassConfigTotalCell label={t('linearMeterAbbrev')} value={formatNumberToFixed(linearMeter)} />
			<GlassConfigTotalCell label={t('kg')} value={formatNumberToFixed(kg, 0)} />
			{
				/* if user is logged in */
				showPrice ? (<GlassConfigTotalCell label={t('price')} value={formatNumberToFixed(price)} />) : null
			}
		</div>
	);
};

export default GlassConfigTotal;
