export default class AbstractGlass {
    constructor({
        ID,
        GLASS_DESCRIPTION,
        ID_VERAS,
        THICKNESS,
        CLEAR_GLASS,
        SOUND_INSULATION,
        M2_WEIGHT,
        RESISTANCE_CLASS,
        UV_PROTECTION,
        APPLICATION,
        MAX_WIDTH,
        MAX_HEIGHT,
        LAYERS,
    }) {
        this.dbIndex = -1;
        this.id = ID;
        this.glasstype = GLASS_DESCRIPTION;
        this.datasheets = null;

        this.id_veras = ID_VERAS;
        this.thickness = parseFloat(THICKNESS.replace(",", "."));
        this.sound = parseFloat(SOUND_INSULATION);
        // CDR: if no sqmWeight is set, use the thickness * 2.5
        this.weight = parseFloat(M2_WEIGHT.replace(",", ".")) || this.thickness * 2.5;
        this.white_glass = CLEAR_GLASS === 'Yes';
        this.resistanceClass = RESISTANCE_CLASS;
        this.uv_protection = UV_PROTECTION;
        this.application = APPLICATION;
        const w = parseFloat(MAX_WIDTH);
        const h = parseFloat(MAX_HEIGHT);
        this.max_width = Math.max(w || 0, h || 0) || NaN; // always use the bigger (or non-NaN) value as width.. rules out issues later when checking max dimensions
        this.max_height = Math.min(w, h);
        this.layers = LAYERS ? JSON.parse(LAYERS) : (this.white_glass ? [this.thickness + ':1'] : [this.thickness + ':0']);
        this.checkLayers();
    }

    checkLayers() {
        const sum = this.layers.reduce((acc, cur) => acc + parseFloat(cur.split(":")[0]), 0.0);
        if (sum !== this.thickness) {
            console.warn(`${this.id}: Glass ${this.glasstype}: Layers (${sum}) don't add up to glass thickness (${this.thickness})`);
        }
    }

    get hasDatasheet() {
        return !!this.datasheets;
    }

    getDatasheet(preferredLanguage = null) {
        const ds = this.datasheets;
        if (!ds) return null;
        switch (preferredLanguage) {
            default:
            case 'de':
                if (ds.datasheet_de) return ds.datasheet_de;
                if (preferredLanguage) return this.getDatasheet();
            case 'fr':
                if (ds.datasheet_fr) return ds.datasheet_fr;
                if (preferredLanguage) return this.getDatasheet();
            case 'en':
                if (ds.datasheet_en) return ds.datasheet_en;
                if (preferredLanguage) return this.getDatasheet();
            case 'it':
                if (ds.datasheet_it) return ds.datasheet_it;
                if (preferredLanguage) return this.getDatasheet('fr');
        }
        return null;
    }
}