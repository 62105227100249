import React from 'react';
import { DEFAULT_GLASS_SIZES, replaceZerosWithDash } from 'domain/glass';

import { View } from '@react-pdf/renderer';
import PdfGlassConfigTableRow from './PdfGlassConfigTableRow';
import PdfGlassConfigTableHeadingRow from './PdfGlassConfigTableHeadingRow';
import PdfGlassConfigTotal from './PdfGlassConfigTotal';

import styles from '../styles';

const PdfGlassConfigTable = ({
	configTableData,
	glassIndex,
	totalValues,
	oversizedGlassFields,
	showPrice,
}) => {
	let total = { ...DEFAULT_GLASS_SIZES };
	if (totalValues?.length) {
		const totalSum = totalValues.reduce((acc, {
			quantity, m2, linearMeterPos, kgPos, price,
		}) => {
			// totals for this glass type
			return {
				quantity: acc.quantity + quantity,
				m2: acc.m2 + m2,
				linearMeter: acc.linearMeter + linearMeterPos,
				kg: acc.kg + kgPos,
				price: (price !== null ? acc.price + price : null),
			};
		}, {
			quantity: 0, m2: 0, linearMeter: 0, kg: 0, price: 0,
		});

		total = replaceZerosWithDash(totalSum);
	}

	return (
		<View>
			<View style={styles.glassConfigTableList}>
				{configTableData.positions && configTableData.positions.length ? (
					<>
						<PdfGlassConfigTableHeadingRow showPrice={showPrice} />
						<View style={styles.block}>
							{configTableData.positions.map((positionRow, rowIndex) => {
								const updatedRowIndex = rowIndex + 1;
								const positionIndex = glassIndex !== undefined ? `${glassIndex}.${updatedRowIndex}` : `1.${updatedRowIndex}`; // 1.1, 1.2 etc

								const emptyRow = positionRow.row.every(
									item => !((item.name === "length" || item.name === "width" || item.name === "quantity") && (item.value !== "")),
								);

								return emptyRow
									? null
									: (
										<PdfGlassConfigTableRow
											key={rowIndex}
											tableRow={positionRow}
											attrKey={rowIndex}
											rowIndex={rowIndex}
											positionIndex={positionIndex}
											totalValues={totalValues?.find(o => o.index === rowIndex)}
											oversizedGlassFields={oversizedGlassFields?.find(o => o.id === positionRow.id)}
											configTableData={configTableData}
											showPrice={showPrice}
										/>
									);
							})}
						</View>
						<PdfGlassConfigTotal {...total} showPrice={showPrice} />
					</>
				) : null}
			</View>
		</View>
	);
};

export default PdfGlassConfigTable;
