/* eslint-disable indent */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import { useTranslation } from "react-i18next";
import { FormCheckbox } from "components/Form";
import classNames from "classnames";

const TermsBlock = ({ isTermsCheckedMissing, setIsTermsChecked }) => {
  const { t } = useTranslation("cartPage");
  const termsLink = t('homePage:termsAndConditionsLink');
  const disclaimerLink = t('homePage:disclaimerLink');
  const termsText = t("termsText").replace('{termsURL}', termsLink).replace('{disclaimerURL}', disclaimerLink);
  const classes = classNames(
    'terms_block',
    { 'terms_block--missing': isTermsCheckedMissing },
  );

  return (
    <div className={classes}>
      <FormCheckbox
        title={`${termsText}`}
        name="terms"
        onChange={() => setIsTermsChecked((prev) => !prev)}
      />
    </div>
  );
};

export default TermsBlock;
