import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import { Field, Formik } from "formik";
import {
	emailValidation,
	telValidation,
	VATNumberValidation,
	nameValidation,
	validatePassword,
	validateConfirmPassword,
	fieldValidation,
	generateUniqueId,
} from "utils";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "utils/firebase";
import { USERS_API } from "api";
import { PROMISE_STATES, USER_ROLES } from "utils/constant";
import { ButtonSubmit } from "components/Buttons";
import { AppDataContext } from "context/appData";
import { AuthContext } from "context/auth";
import { UiContext } from "context/ui";
import ToastifyHandler from "utils/ToastifyHandler";
import jsonAppData from "utils/jsonAppData";
import { sendNewCompanyEmail, sendNewCompanyUserEmail } from "./EmailNotifications";
import FormField from "./FormField";
import ZefixAutocompleteField from './ZefixAutocompleteField';

const RegistrationForm = ({ formInPopup }) => {
	const { t } = useTranslation(["baseFormPlaceholders", 'firebaseErrorMessages']);
	const { showPopupByKey } = useContext(UiContext);
	const { refreshUserTokenAndRoles } = useContext(AuthContext);
	const { langApp } = useContext(AppDataContext);
	const formikRef = useRef();

	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const notificationsHandler = useRef(new ToastifyHandler());

	const validate = {
		userEmail: (email) => emailValidation(email),
		userPassword: (password) => validatePassword(t("userPasswordPlaceholder"), password),
		userRepeatPassword: (repeatPasswordValue) => validateConfirmPassword(
			formikRef.current.values.userPassword,
			repeatPasswordValue,
		),
		userCompanyName: (value) => fieldValidation(t("userCompanyPlaceholder"), value),
		userCompanyLanguage: (value) => fieldValidation(t("userCompanyLanguage"), value),
		userCompanyVATNumber: VATNumber => VATNumberValidation(t('companyVATNumberPlaceholder'), VATNumber),
		userFirstName: (firstName) => nameValidation(t("userFirstNamePlaceholder"), firstName),
		userLastName: (lastName) => nameValidation(t("userLastNamePlaceholder"), lastName),
		userCompanyStreet: (value) => fieldValidation(t("userStreetPlaceholder"), value),
		userCompanyZIP: (value) => fieldValidation(t("userZIPPlaceholder"), value),
		userCompanyCity: (value) => fieldValidation(t("userCityPlaceholder"), value),
		userCompanyPhone: (telNr) => telValidation(telNr),
		userGender: (value) => fieldValidation(t("userGenderPlaceholder"), value),
		userFunction: () => {
			return null;
		},
	};

	const initialValues = {
		userEmail: "",
		userPassword: "",
		userRepeatPassword: "",
		userCompanyName: "",
		userCompanyVATNumber: "",
		userCompanyLanguage: langApp,
		userFirstName: "",
		userLastName: "",
		userCompanyStreet: "",
		userCompanyZIP: "",
		userCompanyCity: "",
		userCompanyPhone: "",
		userGender: "",
		userFunction: "",
	};

	const createNewUser = (
		{
			userEmail, userFirstName, userLastName, userGender, userFunction, userCompanyLanguage,
		},
		companyId,
	) => {
		return {
			email: userEmail,
			companyId,
			name: userFirstName,
			lastName: userLastName,
			role: USER_ROLES.companyAdmin,
			gender: userGender,
			function: userFunction,
			language: userCompanyLanguage,
		};
	};

	const createNewCompany = (
		{
			userEmail,
			userCompanyName,
			userCompanyVATNumber,
			userCompanyStreet,
			userCompanyZIP,
			userCompanyCity,
			userCompanyPhone,
			userCompanyLanguage,
		},
		companyId,
		adminUserId,
	) => {
		return {
			id: companyId,
			name: userCompanyName,
			VATNumber: userCompanyVATNumber,
			address: userCompanyStreet,
			zip: userCompanyZIP,
			city: userCompanyCity,
			tel: userCompanyPhone,
			creatorCompanyEmail: userEmail,
			language: userCompanyLanguage,
			adminUserId,
		};
	};

	const showLoginPopup = (e) => {
		e.stopPropagation();
		if (formInPopup) {
			showPopupByKey("login");
		}
	};

	const onFormSubmit = async (values, setSubmitting) => {
		setSubmitting(true);
		const companyId = generateUniqueId();

		const { userPassword } = values;

		notificationsHandler.current.setAutoCloseDuration(5000);
		notificationsHandler.current.pending(
			t("notifications:accountCreationInProgress"),
		);

		try {
			setLoadingStatus(PROMISE_STATES.pending);

			const newUser = createNewUser(values, companyId);

			const authResult = await createUserWithEmailAndPassword(
				firebaseAuth,
				newUser.email,
				userPassword,
			);
			const { uid } = authResult.user;
			const newCompany = createNewCompany(values, companyId, uid);
			const addNewUserAndCompanyPromise = USERS_API.addNewUserAndCompany(uid, newUser, newCompany);

			// Use Promise.all to wait for all operations to complete - throws as soon as any throws
			const res = await Promise.all([addNewUserAndCompanyPromise]);
			const { companyId: createdCompanyId, duplicateVAT } = res[0].data;
			if (createdCompanyId === companyId) {
				await sendNewCompanyEmail(newUser, newCompany, duplicateVAT);
			} else {
				// User was merged with existing company
				newCompany.id = createdCompanyId;
				await sendNewCompanyUserEmail(newUser, newCompany, t);
			}

			notificationsHandler.current.success(
				t("notifications:accountCreatedSuccessfully"),
			);

			const sleep = async (ms) => new Promise(resolve => { setTimeout(resolve, ms); });
			await sleep(3000);
			refreshUserTokenAndRoles(authResult.user);

			setLoadingStatus(PROMISE_STATES.fulfilled);

			if (formInPopup) {
				showPopupByKey(); // close any popup
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
			setLoadingStatus(PROMISE_STATES.rejected);
		} finally {
			notificationsHandler.current.setAutoCloseDuration(2000);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={(values) => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={(values, { setSubmitting }) => {
				onFormSubmit(values, setSubmitting);
			}}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldTouched,
				setFieldValue,
			}) => (
				<div className="registration_form">
					{!formInPopup && <BlockHeading title={t("registrationForm:title")} />}
					<form
						className="registration_form__in"
						onSubmit={handleSubmit}
						noValidate
					>
						{formInPopup && (
							<BlockHeading title={t("registrationForm:title")} />
						)}
						<ul className="form_fields">
							<li className="form_fields__item info">{t('registrationForm:registerForPrices')}</li>
							{jsonAppData.registrationNewUserForm.map(
								({
									keyForI18n, id, type, iconName, isRequired, options, focusAutoFillValue,
								}) => {
									const i8nLabel = t(`${keyForI18n}`);
									let i8nOptions;

									if (options) {
										i8nOptions = options.map((option) => ({
											...option,
											label: t(`${option.label}`),
										}));
									}
									const placeholder = isRequired ? `${i8nLabel} *` : i8nLabel;

									if (id === 'userCompanyName') {
										return (
											<li className="form_fields__item" key={id}>
												<Field
													id={id}
													name={id}
													className="form_field__input"
													component={ZefixAutocompleteField}
													options={[]}
													placeholder={placeholder}
													setFieldTouched={setFieldTouched}
													setFieldValue={setFieldValue}
												/>
												<div className="form_fields__error">
													{touched[id] && errors[id]}
												</div>
											</li>
										);
									}

									return (
										<li className="form_fields__item" key={id}>
											<FormField
												id={id}
												placeholder={placeholder}
												name={id}
												type={type}
												required={isRequired}
												iconName={iconName}
												values={values}
												focusAutoFillValue={focusAutoFillValue}
												handleBlur={handleBlur}
												handleChange={handleChange}
												options={i8nOptions}
												setFieldTouched={setFieldTouched}
												setFieldValue={setFieldValue}
											/>
											<div className="form_fields__error">
												{touched[id] && errors[id]}
											</div>
										</li>
									);
								},
							)}
						</ul>
						<div className="registration_form__button">
							<ButtonSubmit
								buttonText={t("registrationForm:registerButton")}
								isDisabled={loadingStatus === PROMISE_STATES.pending}
							/>
						</div>
						{formInPopup ? (
							<div className="registration_form__note">
								{t("registrationForm:alreadyHaveAccount")}
								<a
									href="#"
									onClick={showLoginPopup}
								>
									{t("logIn")}
								</a>
							</div>
						) : null}
					</form>
				</div>
			)}
		</Formik>
	);
};

export default RegistrationForm;
