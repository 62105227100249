import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'flex-start',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerLeftGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		boxSizing: 'border-box',
		width: '40%',
	},
	headerCenterGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: 14,
		textTransform: 'uppercase',
		color: '#456d86',
		lineHeight: 0.9,
		width: '20%',
		height: '100%',
		boxSizing: 'border-box',
	},
	headerRightGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		boxSizing: 'border-box',
		width: '40%',
	},
	headerLogo: {
		width: 56,
		height: 35,
		marginRight: 26,
	},
	headerLogoImg: {
		objectFit: 'contain',
		width: '100%',
		height: '100%',
	},
	headerContactInfo: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		maxWidth: 210,
	},
	headerContactInfoItem: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
		textDecoration: 'none', // don't underline links
		textTransform: 'uppercase',
	},
	documentType: {
		fontWeight: 700,
	},
	dueDate: {
		marginTop: '2em',
		fontWeight: 500,
		fontSize: 10,
	},
	headerCustomerInfoItem: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
		textTransform: 'uppercase',
	},
	headerCustomerInfoItemLC: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
	},
});

export default styles;
