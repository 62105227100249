import { firebaseDB, firebaseFunctions } from 'utils/firebase';
import {
	getDoc,
	doc,
	deleteDoc,
	updateDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const COLLECTION_NAME = 'companies';

const updateCompanyData = async (companyId, companyData) => {
	if (!companyId) return null;
	if (!companyData) return null;

	const companyRef = doc(firebaseDB, COLLECTION_NAME, companyId);
	const res = await updateDoc(companyRef, companyData);

	return res;
};

const getCompany = async (companyId) => {
	if (!companyId) return null;

	const snap = await getDoc(doc(firebaseDB, COLLECTION_NAME, companyId));

	if (snap.exists()) {
		return snap.data();
	}

	return null;
};

const getAllCompaniesWithNumAccounts = async () => {
	const listAllCompaniesWithNumAccounts = httpsCallable(
		firebaseFunctions,
		"listAllCompaniesWithNumAccounts",
	);
	return listAllCompaniesWithNumAccounts();
};

const deleteCompany = async (companyId) => {
	if (!companyId) return null;

	const res = await deleteDoc(doc(firebaseDB, COLLECTION_NAME, companyId));

	return res;
};

const COMPANIES_API = {
	updateCompanyData,
	getCompany,
	getAllCompaniesWithNumAccounts,
	deleteCompany,
};

export default COMPANIES_API;
