import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';
import { formatNumberToFixedPdf as formatNumberToFixed } from 'utils';
import PdfGlassConfigDetailItem from './PdfGlassConfigDetailItem';

import styles from '../styles';

const PdfGlassConfigDetailSecond = ({
	configDetails,
	showPrice,
}) => {
	const { t } = useTranslation('commonConfigurationAction');
	return (
		<View style={{ ...styles.glassConfigDetails, ...styles.glassConfigDetailsIndentState }}>
			<PdfGlassConfigDetailItem
				categoryLabel="fireResistanceClass"
				selected={configDetails.fireResistanceClass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="manufacturer"
				selected={configDetails.manufacturer}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="whiteGlass"
				selected={configDetails.whiteGlass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="application"
				selected={configDetails.application}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="structure"
				selected={configDetails.structure}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="insulation"
				selected={configDetails.insulation}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="sound"
				selected={configDetails.sound}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="resistanceClass"
				selected={configDetails.resistanceClass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="buttJoints"
				selected={configDetails.buttJoints}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="sqmWeight"
				selected={formatNumberToFixed(configDetails.flatGlassStructure.sqmWeight, 0)}
				secondLayoutOption
			/>
			{showPrice && (
				<PdfGlassConfigDetailItem
					categoryLabel="sqmPrice"
					selected={formatNumberToFixed(configDetails.flatGlassStructure.sqmPrice || t('unknownPrice'))}
					secondLayoutOption
				/>
			)}
		</View>
	);
};

export default PdfGlassConfigDetailSecond;
