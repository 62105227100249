/* eslint-disable react/jsx-one-expression-per-line, camelcase */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixed } from 'utils';
import { BlockHeading } from 'components/BlockHeading';
import { AppDataContext } from 'context/appData';

export const TotalInfo = ({
	quantity, m2, linearMeter, kg, materialCosts, shippingCosts, subtotal, vatCosts, total, showPrice,
}) => {
	const { configuratorData } = useContext(AppDataContext);
	const { construction_site_delivery_price_from, vat_percent, volume_discount_from_m2 } = configuratorData.pricing;
	const { t } = useTranslation('configurationTableHeading');

	const showIfPriceEnabled = (text) => (showPrice ? text : ' ');
	return (
		<div className="total_info">
			<BlockHeading
				title={t('totalCart')}
				iconName="equals"
			/>
			<div className="total_info_table">
				<div className="total_info__cell light empty" />
				<div className="total_info__cell nobg end_pad_top" />
				<div className="total_info__cell end_pad_bottom" />
				<div className="total_info__cell light inset_pad" />
				<div className="total_info__cell total_txt light">{t('blocktotal')}</div>
				<div className="total_info__cell light qty_unit">{t('pc')}</div>
				<div className="total_info__cell light m2_unit">m²</div>
				<div className="total_info__cell light kg_unit">{t('kg')}</div>
				<div className="total_info__cell light lfm_unit">{t('linearMeterAbbrev')}</div>
				<div className="total_info__cell nobg mat_lbl">{showIfPriceEnabled(t('subtotal'))}</div>
				<div className="total_info__cell nobg ship_lbl">{showIfPriceEnabled(t('lsva'))}</div>
				<div className="total_info__cell subtotal_lbl bold">{showIfPriceEnabled(t('subtotal'))}</div>
				<div className="total_info__cell vat_lbl">{showIfPriceEnabled(`${t('vat')} (${formatNumberToFixed(vat_percent)}%)`)}</div>
				<div className="total_info__cell total_lbl bold">{showIfPriceEnabled(t('totalPriceChf'))}</div>

				<div className="total_info__cell light value qty">{quantity}</div>
				<div className="total_info__cell light value m2">{formatNumberToFixed(m2)}</div>
				<div className="total_info__cell light value kg">{formatNumberToFixed(kg, 0)}</div>
				<div className="total_info__cell light value lfm">{formatNumberToFixed(linearMeter)}</div>

				<div className="total_info__cell sum nobg mat_costs">{formatNumberToFixed(materialCosts)}</div>
				<div className="total_info__cell sum nobg ship_costs">{formatNumberToFixed(shippingCosts)}</div>
				<div className="total_info__cell sum bold subtotal">{formatNumberToFixed(subtotal)}</div>
				<div className="total_info__cell sum vat_costs">{formatNumberToFixed(vatCosts)}</div>
				<div className="total_info__cell sum bold total">{showIfPriceEnabled(formatNumberToFixed(total))}</div>
			</div>
			<div className="total_info__msg">
				<div className="total_info__volume_discount">
					{(m2 >= volume_discount_from_m2) && (
						t('volumeDiscountFmt').replace('{}', volume_discount_from_m2)
					)}
				</div>
				<div className="total_info__delivery">{t('constructionSiteDeliveryFmt').replace('{}', construction_site_delivery_price_from)}</div>
			</div>
		</div>
	);
};

export default TotalInfo;
